import React, { useEffect, useState } from 'react'
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import apiClient from "../../instance/api-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import DeletePrompt from '../../component/prompt/delete-prompt';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function WhiteList() {
    const userRole = useSelector(state => state.user.role)
    const [meta, setMeta] = useState([])
    const [domains, setDomains] = useState([])
    const [domainEdit, setDomainEdit] = useState({
        id: null,
        domain: '',
        allow: "false",
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })
    const [searchFilter, setSearchFilter] = useState({
        name: '',
    })

    useEffect(() => {
        loadDomain()
    }, [])

    const loadDomain = async (searchFilter) => {
        const response = await apiClient.get('admin/whitelist',{ params: searchFilter })
        if (response.data && response.data.data){
            setDomains(response.data.data)
            setMeta(response.data.meta)
        }
            
    }

    const handleEdit = (event) => {
        const domainId = event.currentTarget.getAttribute('data-post-id');
        const edit = domains.filter((domain) => domain.id == domainId)
        if (edit.length > 0) {
            setDomainEdit({ id: edit[0].id, domain: edit[0].domain, allow: edit[0].allow })
        }

    }

    const handleDelete = (event) => {
        const domainId = event.currentTarget.getAttribute('data-post-id');
        const domainValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: domainId,
            value: domainValue
        })
    }

    const handleInputChange = (event) => {
        const id = event.target.id;
        setDomainEdit((prev) => ({
            ...prev,
            [id]: event.target.value
        }));
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleCreateUpdateDomain = async () => {
        let response

        if (domainEdit.domain) {
            if (domainEdit.id)
                response = await apiClient.patch('admin/whitelist/' + domainEdit.id, domainEdit)
            else
                response = await apiClient.post('admin/whitelist', domainEdit)

            if (response.data.statusCode === 200) {
                handleCancelEditDomain()
                loadDomain()
            }
        }
    }

    const handleCancelEditDomain = () => {
        setDomainEdit({ id: null, domain: '', allow: false })
    }

    const handleStatusOnChange = (event) => {
        const isAllow = event.target.value
        setDomainEdit((prev) => ({ ...prev, allow: isAllow }))
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/whitelist/' + id)

            if (response.status === 200) {
                loadDomain()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    const nextPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasNextPage)
            searchTempFilter = { ...searchTempFilter, page: pages === -1 ? meta.pageCount : meta.page + 1 <= meta.pageCount ? meta.page + 1 : meta.pageCount }

            loadDomain(searchTempFilter)
    }

    const prevPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasPreviousPage)
            searchTempFilter = { ...searchTempFilter, page: pages === 1 ? 1 : meta.page - 1 > 0 ? meta.page - 1 : 1 }

            loadDomain(searchTempFilter)
    }

    const changeMetaPage = (event) => {
        const newPage = event.target.value
        if (newPage <= meta.pageCount) {
            setMeta((prev) => ({
                ...prev,
                page: event.target.value
            }))
        } else {
            setMeta((prev) => ({
                ...prev,
                page: prev.pageCount
            }))
        }
    }

    const handleEnterKey = (event) => {
        // if (event.key === 'Enter') {
            // let searchTempFilter = { ...searchFilter }

        //     let searchTempFilter = { ...searchFilter, page: meta.page }

        //     loadCategories(searchTempFilter)
        // }
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Domain"
                    message={`Domain : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">
                    <div className="card mb-4">
                        <div className="card-header pb-0 subsubsub2">
                            <h6>All Domains</h6>
                            <span className="count">({meta.itemCount | 0})</span>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Domain</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Alllow</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Created</th>
                                            {userRole === "SUPER ADMIN" && (<>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Edit</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Delete</th>
                                            </>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domains && domains.length > 0 && domains.flatMap((domain, index) => {
                                            return (
                                                <tr key={'domain' + index}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{domain.domain}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{domain.allow ? "Allow" : " Not Allow"}</p>
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                                                        <span className="text-secondary text-xs font-weight-bold">{formatDate(domain.createdAt)}</span>
                                                    </td>
                                                    {userRole === "SUPER ADMIN" && (<>
                                                        <td className="align-middle text-center">
                                                            <a className="btn btn-link text-dark px-3 mb-0" href={null} data-post-id={domain.id} onClick={handleEdit}>
                                                                <FontAwesomeIcon icon={faPencil} /> Edit</a>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <a className="btn btn-link text-danger px-3 mb-0" href={null} data-post-id={domain.id} data-post-value={domain.domain} onClick={handleDelete}>
                                                                <FontAwesomeIcon icon={faTrash} /> Delete</a>
                                                        </td>
                                                    </>)}

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="tablenav-pages">
                                    <span className="displaying-num">{meta.itemCount} items</span>
                                    <span className="pagination-links">
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={() => prevPage(1)}>«</span>
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={prevPage}>‹</span>
                                        <span className="paging-input">
                                            <label htmlFor="current-page-selector" className="screen-reader-text">Current Page</label>
                                            <input className="current-page" id="current-page-selector" type="text" name="paged" defaultValue={1} size={1} value={meta && meta.page && meta.page} aria-describedby="table-paging" onChange={changeMetaPage} onKeyDown={handleEnterKey} />
                                            <span className="tablenav-paging-text"> of  <span className="total-pages">{meta.pageCount}
                                            </span>
                                            </span>
                                        </span>
                                        <a className="next-page button" onClick={nextPage}>
                                            <span className="screen-reader-text">Next page</span>
                                            <span aria-hidden="true">›
                                            </span>
                                        </a>
                                        <a className="last-page button" onClick={() => nextPage(-1)}>
                                            <span className="screen-reader-text" >Last page</span>
                                            <span aria-hidden="true">»</span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userRole === "SUPER ADMIN" && (<div className="card mb-4">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h6 className="mb-0">Add New Domain</h6>
                                    <p className="text-xs form-text text-muted3 ms-0">Type in the domain, is allow and &apos;CLICK&apos; + Add New Domain</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-margin-top">
                                <div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label">Domain</label>
                                            <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="domain" onChange={handleInputChange} value={domainEdit.domain ? domainEdit.domain : ''} />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Is Allow</label>
                                            <select className="form-control3 form-control-sm" onChange={handleStatusOnChange} value={domainEdit.allow}>
                                                <option value={true}>Allow</option>
                                                <option value={false}>Not Allow</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="MuiBox-root css-viexu0">
                                        <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                        <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: domainEdit.id ? '#82d616' : 'rgb(68 197 255)' }} onClick={handleCreateUpdateDomain}>

                                            {/* btn mb-0 bg-gradient-info btn-md null null mb-0 */}
                                            {domainEdit.id ?
                                                (<>
                                                    <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa " aria-hidden="true"></span>Update Domain
                                                </>) :
                                                (<>
                                                    <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>Add New Domain
                                                </>)
                                            }
                                        </button>
                                        <p></p>
                                        {domainEdit.id && (
                                            <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: 'gray' }} onClick={handleCancelEditDomain}>
                                                Cancel
                                            </button>
                                        )}

                                    </div>
                                </div>

                                {/* <!-- Footer --> */}

                                <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                            </form>
                        </div>
                    </div>)}

                </div >
            </div >
        </>
    )
}
WhiteList.propTypes = {
    userRole: PropTypes.string,
};

