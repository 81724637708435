import React, { useState, useEffect } from "react";
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import './library.css'
import apiClient from "../../instance/api-client";
import Thumbnail from '../../component/image-modal/thumbnail'
import FloatingImageModel from "../../component/floating-image-modal/floating-image-modal";

export default function Library() {
    const [currentImgOpen, setCurrentImgOpen] = useState(null);
    const [modalBodySrc, setModalBodySrc] = useState(false);
    const [thumbnails, setThumbnails] = useState([])

    useEffect(() => {
        loadImage()
    }, [])

    const handleThumbnailClick = (object) => {
        setCurrentImgOpen(object)
        document.body.style.overflow = 'hidden'
        setModalBodySrc(true)
    };

    const loadImage = async () => {
        const response = await apiClient.get('images')

        setThumbnails(response.data)
    }

    const closeModel = () => {
        setModalBodySrc(false)
        setCurrentImgOpen(null)
        document.body.style.overflow = 'unset'
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    return (
        <div >
            <div>
                {modalBodySrc && (
                    <FloatingImageModel currentImgOpen={currentImgOpen} closeModel={closeModel} />
                )}
            </div>


            <div className="g-sidenav-show">
                <SideMenu />
                <Header />

                <div className="main-content" id="panel">
                    <div className="container-fluid2 pt-3 row removable">
                        <div className="mt-4 margin-right row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header pb-0"></div>

                                    {/* <!--Card-Body-Content_HERE--> */}
                                    <div className="card-body px-0 pb-2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group2 form-margin-top">
                                                    {/* <!-- Media-Library --> */}
                                                    <div className="container">
                                                        <div className="row">
                                                            {thumbnails.map((thumbnail, index) => {
                                                                return (
                                                                    <Thumbnail
                                                                        key={index}
                                                                        src={process.env.REACT_APP_API_URL + 'uploads/' + thumbnail.filename}
                                                                        title={thumbnail.title}
                                                                        input={thumbnail.input}
                                                                        uploadBy={thumbnail.user ? thumbnail.user.name : null}
                                                                        uploadDate={formatDate(thumbnail.createdAt)}
                                                                        fileName={thumbnail.filename}
                                                                        fileSize={thumbnail.fileSize}
                                                                        onClick={handleThumbnailClick}
                                                                        alt={thumbnail.alt}
                                                                    />
                                                                )
                                                            }

                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}