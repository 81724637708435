import Header from '../../component/header/header'
import SideMenu from '../../component/side-menu/side-menu'
import React, { useEffect, useState } from "react";
import apiClient from "../../instance/api-client";
import TabNavItem from 'component/tab-nav-item/tab-nav-item';
import TabContent from 'component/tab-content/tab-content';

export default function Banner() {

    const emptyBanner = {
        href: null,
        img: null,
        full: null,
    }
    const [bannerList, setBannerList] = useState({
        mainTop: {...emptyBanner},
        mainMid: {...emptyBanner},
        mainBtm: {...emptyBanner},
        allTop: {...emptyBanner},
        allBtm: {...emptyBanner},
        allSid: {...emptyBanner},
        contentTop:  {...emptyBanner} ,
        contentBtm:  {...emptyBanner} ,
        contentSid:  {...emptyBanner} ,
        // rightBottom: {
        //     href: null,
        //     img: null,
        //     full: null,
        // },
        // side: {
        //     href: null,
        //     img: null,
        //     full: null,
        // },
        qrCode: {
            href: null,
            img: null,
            full: null,
        },
    })

    const [alert, setAlert] = useState({
        show: false,
        message: '',
        status: false,
    })

    useEffect(() => {
        loadBanner()
    }, [])

    const handleOnChangeBanner = (event) => {
        const id = event.target.id;
        if (id !== 'qrCode') {
            setBannerList((prev) => ({
                ...prev,
                [id]: {
                    full: event.target.value,
                    img: getImageSrcFromValue(event.target.value),
                    href: getHrefFromValue(event.target.value)
                }
            }));
        } else {
            setBannerList((prev) => ({
                ...prev,
                qrCode: {
                    ...prev.qrCode,
                    href: event.target.value
                }
            }));
        }
    }


    const loadBanner = async () => {
        try {
            const response = await apiClient.get('admin/banner');

            if (response) {
                for (const banner of response.data) {
                    setBannerList((prev) => ({
                        ...prev, [banner.position]: {
                            href: banner.href,
                            img: banner.img,
                            full: banner.full,
                        }
                    }))
                }
            }

        } catch (error) {
            console.info(error)
        }
    }

    const saveBanner = async () => {
        const param = []

        for (const key in bannerList) {
            if (Object.hasOwnProperty.call(bannerList, key)) {
                const element = bannerList[key];

                if (element.href)
                    param.push({
                        position: key,
                        href: element.href,
                        img: element.img,
                        full: element.full
                    })
            }
        }

        const response = await apiClient.bulkCreate(param, "banner")

        if (response && response.statusCode === 200) {
            setAlert({ message: "Success Update Banner", show: true, status: true })
        }
    }

    const processSaveBanner = () => {
        saveBanner()
    }

    const getHrefFromValue = (value) => {
        if (value) {
            const hrefRegex = /<a\s+href='([^']+)'/;
            // Extract href value using the hrefRegex
            const hrefMatch = value.match(hrefRegex);
            const hrefValue = hrefMatch ? hrefMatch[1] : null;
            return hrefValue
        }
    }

    const getImageSrcFromValue = (value) => {
        if (value) {
            const srcRegex = /<img\s+src='([^']+)'/;
            const srcMatch = value.match(srcRegex);
            const srcValue = srcMatch ? srcMatch[1] : null;
            return srcValue.replace("\" + t.getTime() + \"", '')
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size;

            if (fileValidate(fileType, fileSize)) {
                const image = await apiClient.apiPostNewsImage({ file, description: "", alt: "", fileSize: fileSize })
                setBannerList((prev) => ({
                    ...prev,
                    qrCode: {
                        ...prev.qrCode,
                        img: image.filename
                    }
                }));
            }


        }
    };

    const fileValidate = (fileType, fileSize) => {
        const imagesTypes = ["jpeg", "png", "svg", "gif"];
        const isImage = imagesTypes.includes(fileType.split('/')[1]);

        if (!isImage) {
            alert("Please make sure to upload an Image File Type");
            return false;
        }

        if (fileSize > 2000000) {
            alert("Please note that your file should be less than 2 Megabytes");
            return false;
        }

        return true;
    };

    const handleClose = () => {
        setAlert({ message: '', show: false })
    }

    const [activeTab, setActiveTab] = useState("tab1");

    return (
        <>
            <SideMenu />
            <Header />
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">
                    <div className="card card-body align-items-center flex-row flex-md-row">
                        <form className="d-flex align-items-center w-100 justify-content-between" acceptCharset="UTF-8" data-gtm-form-interact-id={0}>

                            <div id="Tabs">
                                {/* Tab nav */}
                                <ul className="nav">
                                    <TabNavItem title="Main Page" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab} />
                                    <TabNavItem title="All Page" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} />
                                    <TabNavItem title="Content Page" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab} />
                                    <TabNavItem title="QR code" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab} />
                                </ul>
                                <div className="outlet" >
                                    <TabContent id="tab1" activeTab={activeTab} >
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Main Page 1070*290 </span>
                                            <textarea type="text" id='mainTop' value={bannerList.mainTop.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Main Page 1000*90 </span>
                                            <textarea type="text" id='mainMid' value={bannerList.mainMid.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Main Page 1000*75 </span>
                                            <textarea type="text" id='mainBtm' value={bannerList.mainBtm.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                    </TabContent>
                                    <TabContent id="tab2" activeTab={activeTab}>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > All / Categories Page 1070*290 </span>
                                            <textarea type="text" id='allTop' value={bannerList.allTop.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > All / Categories Page 1000*90 </span>
                                            <textarea type="text" id='allBtm' value={bannerList.allBtm.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > All / Categories Page 300*300 </span>
                                            <textarea type="text" id='allSid' value={bannerList.allSid.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                    </TabContent>
                                    <TabContent id="tab3" activeTab={activeTab}>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Content Page 1070*290 </span>
                                            <textarea type="text" id='contentTop' value={bannerList.contentTop.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Content Page 1000*90 </span>
                                            <textarea type="text" id='contentBtm' value={bannerList.contentBtm.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                        <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span > Content Page 300*300 </span>
                                            <textarea type="text" id='contentSid' value={bannerList.contentSid.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                        </div>
                                    </TabContent>
                                    <TabContent id="tab4" activeTab={activeTab} style={{ display: 'flex', flexDirection: 'column' }}>
                                        {bannerList.qrCode.img &&

                                            (<img src={`${process.env.REACT_APP_API_URL}uploads/${bannerList.qrCode.img}`} style={{ width: '100px', height: '100px' }} />)
                                        }

                                        <input type="file" id="fileInput" className="drop-zoon__file-input" accept="image/*" onChange={handleFileChange} />
                                        <textarea type="text" id='qrCode' value={bannerList.qrCode.href} autoComplete="off" style={{ width: '800px', height: '50px' }} onChange={handleOnChangeBanner} />
                                        <button className="btn bg-gradient-warning" style={{ width: '200px' }} type="button" name="uploadQr" onClick={() => { document.querySelector('#fileInput').click(); }}>upload QR</button>
                                    </TabContent>
                                    <button className="btn bg-gradient-success" style={{ width: '200px', marginTop: '20px' }} type="button" name="button" onClick={processSaveBanner}>Save</button>
                                </div>
                            </div>

                        </form>

                    </div>

                </div>
            </div>
            {/* <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">
                    <div className="card card-body align-items-center flex-row flex-md-row">
                        <form className="d-flex align-items-center w-100 justify-content-between" acceptCharset="UTF-8" data-gtm-form-interact-id={0}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span > Main Page 1000*90 </span>
                                    <textarea type="text" id='main' value={bannerList.main.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                </div>

                                <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span > All Page 1070*290 </span>
                                    <textarea type="text" id='all' value={bannerList.all.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                </div>
                                <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span > Right Bottom 200*200 </span>
                                    <textarea type="text" id='rightBottom' value={bannerList.rightBottom.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                </div>
                                <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span > Side 300*300 </span>
                                    <textarea type="text" id='side' value={bannerList.side.full} autoComplete="off" style={{ width: '800px', height: '200px' }} onChange={handleOnChangeBanner} />
                                </div>
                                <div className="form-check form-switch ms-2 mb-0 me-auto flex-column" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span > QR code </span>
                                    {bannerList.qrCode.img &&

                                        (<img src={`${process.env.REACT_APP_API_URL}uploads/${bannerList.qrCode.img}`} style={{ width: '100px', height: '100px' }} />)
                                    }

                                    <input type="file" id="fileInput" className="drop-zoon__file-input" accept="image/*" onChange={handleFileChange} />
                                    <textarea type="text" id='qrCode' value={bannerList.qrCode.href} autoComplete="off" style={{ width: '800px', height: '50px' }} onChange={handleOnChangeBanner} />
                                    <button type="button" name="uploadQr" onClick={() => { document.querySelector('#fileInput').click(); }}>upload QR</button>
                                </div>
                                <button className="btn bg-gradient-success" style={{ width: '200px', marginTop: '20px' }} type="button" name="button" onClick={processSaveBanner}>Save</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div> */}
            {alert.show && (<div className={`loopple-alert loopple-alert-dismissible loopple-fade loopple-position-fixed loopple-z-index-9999 loopple-top-2 loopple-mx-auto loopple-text-center loopple-right-0 loopple-left-0 loopple-w-50 ${alert.status ? 'bg-gradient-success' : 'bg-gradient-warning'}`} role="alert">
                <strong>{alert.message}</strong>
                {/*<a class="loopple-btn loopple-btn-white loopple-ml-2" href="https://builder.creative-tim.com/builder/project-YCW2KXyKEB8VlvNDRwhrt6dlWrTAV5x9a2X" target="_blank">Editor</a>*/}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" onClick={handleClose}>✖</span></button>
            </div>)}
        </>
    )
}