import React from 'react';
import PropTypes from 'prop-types'

const FloatingImageModel = ({ currentImgOpen, closeModel }) => {
    return (
        <div className="floating-model" onClick={closeModel}>
            <div className="floating-model-body" onClick={(e) => e.stopPropagation()}>
                <div style={{ padding: '20px', margin: '20px' }}>
                    <p>FileName : {currentImgOpen.fileName}</p>
                    <p>Size : {currentImgOpen.fileSize}</p>
                    <p>Alt : {currentImgOpen.alt}</p>
                    <p>Upload by: {currentImgOpen.uploadBy}</p>
                    <p>Upload On: {currentImgOpen.uploadDate}</p>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <img src={currentImgOpen.src} style={{ maxWidth: '100%' }} alt="Uploaded" />
                    </div>
                </div>
            </div>
        </div>
    );
};

FloatingImageModel.propTypes = {
    currentImgOpen: PropTypes.arrayOf(
        PropTypes.shape({
            fileName: PropTypes.string.isRequired,
            fileSize: PropTypes.string.isRequired,
            uploadBy: PropTypes.string.isRequired,
            uploadDate: PropTypes.string.isRequired,
            src: PropTypes.string.isRequired,
            alt: PropTypes.string
            // Add other prop validations as needed
        })
    ).isRequired,
    closeModel: PropTypes.func.isRequired
};

export default FloatingImageModel;