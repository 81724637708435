import React, { useRef, useState, useEffect, useMemo } from "react"
import ReactQuill, { Quill } from "react-quill";
import apiClient from "../../instance/api-client";
import PropTypes from 'prop-types';
import './rich-text-editor.css'

RichTextEditor.propTypes = {
    content: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

var Image = Quill.import('formats/image');

class Images extends Image {
    static create(data) {
        const node = super.create(data);
        node.setAttribute('src', data.src);
        node.setAttribute('id', data.id);
        node.setAttribute('alt', data.alt);
        return node;
    }
    static value(domNode) {
        const src = domNode.getAttribute('src');
        const id = domNode.getAttribute('id');
        const alt = domNode.getAttribute('alt');
        return { src, id, alt };
    }
}
Images.tagName = 'img';
Quill.register({ 'formats/image': Images });

export default function RichTextEditor(prop) {
    const { content, onChange } = prop;
    const [showDialog, setShowDialog] = useState(false);
    const [altText, setAltText] = useState('');
    const [description, setDescription] = useState('');
    const [imageFile, setImageFile] = useState(null)

    const quillRef = useRef(null);
    useEffect(() => {
        // @ts-ignore
        quillRef.current
            .getEditor()
            .getModule('toolbar')
            .addHandler('image', () => {
                const input = document.createElement('input')
                input.setAttribute('type', 'file')
                input.setAttribute('accept', 'image/*')
                input.click()
                input.onchange = async () => {
                    if (!input.files || !input?.files?.length || !input?.files?.[0])
                        return

                    const file = input.files[0]
                    setImageFile(file)
                    setShowDialog(true)
                    // handleImage(file)
                }
            })
    }, [quillRef])

    const handleImage = async (file, description, alt) => {

        const image = await apiClient.apiPostNewsImage({ file, description: description, alt: alt })
        const reader = new FileReader();
        reader.onload = () => {

            const range = quillRef.current.getEditor().getSelection(true);
            quillRef.current.getEditor().insertEmbed(
                range.index,
                'image',
                {
                    src: process.env.REACT_APP_API_URL + 'uploads/' + image.filename,
                    id: image.id,
                    alt: image.alt
                }
                // process.env.REACT_APP_API_URL + 'uploads/' + image.filename,

            )

        };
        reader.readAsDataURL(file);
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: [

                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                ["link", "image"],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { align: [] }
                ],
                [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
            ]
        },

    }), [])

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const handleDropImage = (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        if (file) {
            setImageFile(file)
            setShowDialog(true)
            // handleImage(file)
        }
    };

    const handleImageInsert = () => {
        handleImage(imageFile, description, altText)
        setShowDialog(false)
    }

    return (
        <div >
            {showDialog && (
                <>
                    <div className="overlay short" id="overlay" style={{ marginTop: '-200px' }}>
                        <div className="overlay-background" id="overlay-background" ></div>
                        <div className="overlay-content" id="overlay-content">
                            <div className="fa fa-times fa-lg overlay-open" id="overlay-open" onClick={() => setShowDialog(false)}></div>
                            <h1 className="main-heading">Add alt text to your picture</h1>
                            <h3 className="blurb">Add your &quot;Alt Text&quot; and &quot;Description&quot; below —</h3><span className="blurb-tagline"></span>
                            <div className="signup-form" >
                                <label htmlFor="alt">Alt</label>
                                <input id="alt" type="text" name="alt" autoComplete="off" value={altText} onChange={(e) => setAltText(e.target.value)} />
                                <label htmlFor="description">Description</label>
                                <input id="signup-email" type="email" name="email" autoComplete="off" value={description} onChange={(e) => setDescription(e.target.value)} />
                                <button className="btn btn-outline" onClick={handleImageInsert}><span>add</span></button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div onDrop={handleDropImage} style={{ minHeight: '240px' }}>
                <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    modules={modules}
                    formats={formats}
                    value={content}
                    placeholder="write your content ...."
                    onChange={onChange}
                    style={{ minHeight: "220px" }}
                >
                </ReactQuill>

            </div>
        </div>
    )
}