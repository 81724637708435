import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil, faSearch } from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../../component/side-menu/side-menu"
import Header from "../../component/header/header"
import apiClient from "../../instance/api-client";
import DeletePrompt from "../../component/prompt/delete-prompt";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'

export default function Categories() {
    const userRole = useSelector(state => state.user.role)
    const [meta, setMeta] = useState([])
    const [categories, setCategories] = useState([])
    const [categoryEdit, setCategoryEdit] = useState({
        id: null,
        name: '',
        urlName: null,
        slug: null,
        description: null
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })
    const [searchFilter, setSearchFilter] = useState({
        name: '',
    })

    useEffect(() => {
        loadCategories()
    }, [])

    const loadCategories = async (searchFilter) => {
        try {
            const response = await apiClient.get('admin/categories', { params: searchFilter })

            if (response.status === 200) {
                setCategories(response.data.data)
                setMeta(response.data.meta)
            }
        } catch (error) {
            alert(error.response.data.message)
        }
    }

    const handleEdit = (event) => {
        const categoryId = event.currentTarget.getAttribute('data-post-id');
        const edit = categories.filter((category) => category.id == categoryId)
        if (edit.length > 0) {
            setCategoryEdit({ id: edit[0].id, slug: edit[0].slug, description: edit[0].description, name: edit[0].name, urlName: edit[0].urlName })
        }
    }

    const handleDelete = (event) => {
        const categoryId = event.currentTarget.getAttribute('data-post-id');
        const categoryValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: categoryId,
            value: categoryValue
        })

    }

    const handleCreateUpdateCategory = async () => {
        let response

        if (categoryEdit.name) {
            try {
                if (categoryEdit.id)
                    response = await apiClient.patch('admin/categories/' + categoryEdit.id, categoryEdit)
                else
                    response = await apiClient.post('admin/categories', categoryEdit)

                if (response.data.statusCode === 200) {
                    handleCancelEditCategory()
                    loadCategories()
                }
            } catch (error) {
                alert(error.response.data.message)
            }

        }
    }

    const handleCancelEditCategory = () => {
        setCategoryEdit({ id: null, slug: '', description: '', name: '' })
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleInputChange = (event) => {
        const id = event.target.id;
        setCategoryEdit((prev) => ({
            ...prev,
            [id]: event.target.value.replace(' ', '-')
        }));
    }

    const nextPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasNextPage)
            searchTempFilter = { ...searchTempFilter, page: pages === -1 ? meta.pageCount : meta.page + 1 <= meta.pageCount ? meta.page + 1 : meta.pageCount }

        loadCategories(searchTempFilter)
    }

    const prevPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasPreviousPage)
            searchTempFilter = { ...searchTempFilter, page: pages === 1 ? 1 : meta.page - 1 > 0 ? meta.page - 1 : 1 }

        loadCategories(searchTempFilter)
    }

    const handleOnChangeSearchName = (event) => {
        setSearchFilter((prev) => ({ ...prev, name: event.target.value }))
    }

    const handleSearchPosts = () => {
        loadCategories(searchFilter)
    }


    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/categories/' + id)

            if (response.status === 200) {
                loadCategories()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    const changeMetaPage = (event) => {
        const newPage = event.target.value
        if (newPage <= meta.pageCount) {
            setMeta((prev) => ({
                ...prev,
                page: event.target.value
            }))
        } else {
            setMeta((prev) => ({
                ...prev,
                page: prev.pageCount
            }))
        }
    }

    const handleEnterKey = (event) => {
        if (event.key === 'Enter') {
            // let searchTempFilter = { ...searchFilter }

            let searchTempFilter = { ...searchFilter, page: meta.page }

            loadCategories(searchTempFilter)
        }
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Categories"
                    message={`Categories : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">
                    <div className="card mb-4">
                        <div className="card-header pb-0 subsubsub2">
                            <h6>All Categories</h6>
                            <span className="count">({meta.itemCount | 0})</span>
                            <div className="input-group2 text-end">
                                <span className="input-group-text2 text-body"><span className="input-group-text2 text-body"><FontAwesomeIcon icon={faSearch} /></span></span>
                                <input type="text" className="form-control" value={searchFilter.name ? searchFilter.name : ''} onChange={handleOnChangeSearchName} placeholder="Type here..." />
                                <div className="col-5 text-end">
                                    <a onClick={handleSearchPosts} className="btn btn-sm bg-gradient-primary-blue mb-0" href={undefined}>Search</a>
                                </div>
                            </div>
                        </div>

                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">URL Name</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Slug</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descriptions</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Created</th>
                                            {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (<>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Edit</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Delete</th>
                                            </>)}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {categories && categories.length > 0 && categories.flatMap((category, index) => {
                                            return (
                                                <tr key={'category' + index} >
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{category.name}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{category.urlName}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{category.slug}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{category.description}</p>
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                                                        <span className="text-secondary text-xs font-weight-bold">{formatDate(category.createdAt)}</span>
                                                    </td>
                                                    {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (
                                                        <>
                                                            <td className="align-middle text-center">
                                                                <a className="btn btn-link text-dark px-3 mb-0" href={null} data-post-id={category.id} onClick={handleEdit}>
                                                                    <FontAwesomeIcon icon={faPencil} /> Edit</a>
                                                            </td>
                                                            <td className="align-middle text-center">
                                                                <a className="btn btn-link text-danger px-3 mb-0" href={null} data-post-id={category.id} data-post-value={category.name} onClick={handleDelete}>
                                                                    <FontAwesomeIcon icon={faTrash} /> Delete</a>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="tablenav-pages">
                                    <span className="displaying-num">{meta.itemCount} items</span>
                                    <span className="pagination-links">
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={() => prevPage(1)}>«</span>
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={prevPage}>‹</span>
                                        <span className="paging-input">
                                            <label htmlFor="current-page-selector" className="screen-reader-text">Current Page</label>
                                            <input className="current-page" id="current-page-selector" type="text" name="paged" defaultValue={1} size={1} value={meta && meta.page && meta.page} aria-describedby="table-paging" onChange={changeMetaPage} onKeyDown={handleEnterKey} />
                                            <span className="tablenav-paging-text"> of  <span className="total-pages">{meta.pageCount}
                                            </span>
                                            </span>
                                        </span>
                                        <a className="next-page button" onClick={nextPage}>
                                            <span className="screen-reader-text">Next page</span>
                                            <span aria-hidden="true">›
                                            </span>
                                        </a>
                                        <a className="last-page button" onClick={() => nextPage(-1)}>
                                            <span className="screen-reader-text" >Last page</span>
                                            <span aria-hidden="true">»</span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (
                        <div className="card mb-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h6 className="mb-0">Add New Category</h6>
                                        <p className="text-xs form-text text-muted3 ms-0">Type in the name, slug and descriptions and &apos;CLICK&apos; + Add New Category</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form-margin-top">
                                    <div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="form-label">Name</label>
                                                <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="name" onChange={handleInputChange} value={categoryEdit.name ? categoryEdit.name : ''} />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">URL Name</label>
                                                <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="urlName" onChange={handleInputChange} value={categoryEdit.urlName ? categoryEdit.urlName : ''} />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Slug</label>
                                                <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: worldcup, chelsea, ac-milan, christiano-ronaldo" id="slug" onChange={handleInputChange} value={categoryEdit.slug ? categoryEdit.slug : ''} />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Descriptions</label>
                                                <input type="text" data-input="true" className="form-control2" placeholder="i.e: *English Premier Leagues fixture, livescore, standings and player transfer related topic. (*Optional-You may leave it blank as well.)" id="description" onChange={handleInputChange} value={categoryEdit.description ? categoryEdit.description : ''} />
                                            </div>
                                        </div>

                                        <div className="MuiBox-root css-viexu0">
                                            <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                            <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: categoryEdit.id ? '#82d616' : 'rgb(68 197 255)' }} onClick={handleCreateUpdateCategory}>
                                                {categoryEdit.id ?
                                                    (<>
                                                        <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true"></span>Update Category
                                                    </>) :
                                                    (<>
                                                        <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>Add New Category
                                                    </>)
                                                }
                                            </button>
                                            <p></p>
                                            {categoryEdit.id && (
                                                <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: 'gray' }} onClick={handleCancelEditCategory}>
                                                    Cancel
                                                </button>
                                            )}

                                        </div>
                                    </div>

                                    {/* <!-- Footer --> */}

                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                                </form>
                            </div>
                        </div>
                    )}

                </div >
            </div >
        </>
    )
}

Categories.propTypes = {
    userRole: PropTypes.string,
};
