// import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import Login from './login/login';
import Post from './post/all-post/all-post';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import io from 'socket.io-client';
import NewPost from './post/new-post/new-post';
import Categories from './post/categories/categories';
import Tags from './post/tags/tags';
import AllMainPages from './pages/all-main-pages/all-main-pages';
import AllSubPages from './pages/all-sub-pages/all-sub-pages';
import SubCategories from './post/sub-categories/sub-categories';
import Library from './media/library/library';
import AddMedia from './media/add-media/add-media';
import Domain from './settings/domain/domain';
import Dashboard from './dashboard/dashboard';
import User from './user/user';
import Banner from 'affiliate/banner/banner';
import WhiteList from 'settings/whitelist/whitelist';

function App() {

  useEffect(() => {

    const socket = io(process.env.REACT_APP_WS_URL, {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false
    });

    socket.on('connect', () => {
      console.info('isConnected');
      socket.on('post', receiveMessage);
    });
    socket.on('disconnect', onDisconnect);

    socket.on('testing', (data) => {
      console.info("testing : ", data)
    });

    socket.on('message', (data) => {

      console.info("message : ", data)
    });

    socket.on('post', (data) => {
      console.info("post : ", data)
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs only once

  function receiveMessage(value) {
    console.info(value)
  }

  function onDisconnect() {
    // setIsConnected(false);
  }

  return (
    <>
      <div className="desktop">
      </div>
      <div className="message">
        <a>For Desktop Access Only</a>
      </div>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<Login />}
          />

          <Route
            path="/Login"
            element={<Login />}
          />


          {/* Dashboard */}
          <Route
            path="/Dashboard"
            element={<Dashboard />}
          />

          {/* Post */}
          <Route
            path="/AllPost"
            element={<Post />}
          />
          <Route
            path="/NewPost"
            element={<NewPost />}
          />
          <Route
            path="/EditPost/:id"
            element={<NewPost />}
          />
          <Route
            path="/Categories"
            element={<Categories />}
          />
          <Route
            path="/SubCategories"
            element={<SubCategories />}
          />
          <Route
            path="/Tags"
            element={<Tags />}
          />

          {/* Pages */}
          <Route
            path="/MainPage"
            element={<AllMainPages />}
          />
          <Route
            path="/SubPage"
            element={<AllSubPages />}
          />

          {/* Media */}
          <Route
            path="/Library"
            element={<Library />}
          />
          <Route
            path="/AddNewFile"
            element={<AddMedia />}
          />
          {/* Affiliate */}
          <Route
            path="/Banner"
            element={<Banner />}
          />
          {/* Settings */}
          <Route
            path="/Domain"
            element={<Domain />}
          />
          <Route
            path="/WhiteList"
            element={<WhiteList />}
          />

          {/* User */}
          {/* <Route
            path="/AllUser"
            element={<AllUser />}
          />
          <Route
            path="/AddNewUser"
            element={<AddNewUser />}
          />
          <Route
            path="/Profile"
            element={<></>}
          /> */}
          <Route
            path="/User"
            element={<User />}
          />

          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
