import { SET_USER } from '../actionType/actionTypes';

const setUser = (role, token) =>
({
    type: SET_USER,
    role: role,
    token: token
});

const logOut = () => {
    return {
        type: "LOGOUT"
    }
}
export {
    setUser,
    logOut
}