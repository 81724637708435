import React from "react";
import PropTypes from 'prop-types'
const TabContent = ({ id, activeTab, children , style }) => {
    return (
        activeTab === id ? <div className="TabContent" style={style&&style}>
            {children}
        </div>
            : null
    );
};
TabContent.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    children: PropTypes.element,
    style: PropTypes.style
};

export default TabContent;