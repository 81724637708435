import React, { useEffect, useState } from 'react'
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import apiClient from "../../instance/api-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import DeletePrompt from '../../component/prompt/delete-prompt';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function Domain() {
    const userRole = useSelector(state => state.user.role)
    const [meta, setMeta] = useState([])
    const [domains, setDomains] = useState([])
    const [domainEdit, setDomainEdit] = useState({
        id: null,
        brandName: '',
        domain: '',
        description: '',
        mainTitle: '',
        mainDesc: '',
        mainKey: '',
        leagueTitle: '',
        leagueDesc: '',
        leagueKey: '',
        intTitle: '',
        intDesc: '',
        intKey: '',
        footTitle: '',
        footDesc: '',
        footKey: '',
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })
    const [searchFilter, setSearchFilter] = useState({
        name: '',
    })

    useEffect(() => {
        loadDomain()
    }, [])

    const loadDomain = async (searchFilter) => {
        const response = await apiClient.get('server-hosting', { params: searchFilter })

        console.info(response.data)
        if (response.data && response.data.data) {
            setDomains(response.data.data)
            setMeta(response.data.meta)
        }

    }

    const handleEdit = (event) => {
        const domainId = event.currentTarget.getAttribute('data-post-id');
        const edit = domains.filter((domain) => domain.id == domainId)
        if (edit.length > 0) {
            // setDomainEdit({ id: edit[0].id, brandName: edit[0].brandName, domain: edit[0].domain, description: edit[0].description })

            console.info(edit[0])
            setDomainEdit({ ...edit[0] })
        }

    }

    const handleDelete = (event) => {
        const domainId = event.currentTarget.getAttribute('data-post-id');
        const domainValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: domainId,
            value: domainValue
        })
    }

    const handleInputChange = (event) => {
        const id = event.target.id;
        setDomainEdit((prev) => ({
            ...prev,
            [id]: event.target.value
        }));
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleCreateUpdateDomain = async () => {
        let response

        if (domainEdit.domain) {
            if (domainEdit.id)
                response = await apiClient.patch('admin/server-hosting/' + domainEdit.id, domainEdit)
            else
                response = await apiClient.post('admin/server-hosting', domainEdit)

            if (response.data.statusCode === 200) {
                handleCancelEditDomain()
                loadDomain()
            }
        }
    }

    const handleCancelEditDomain = () => {
        setDomainEdit({ id: null, brandName: '', domain: '', description: '', allow: false })
    }

    const handleStatusOnChange = (event) => {
        const isAllow = event.target.value
        setDomainEdit((prev) => ({ ...prev, allow: isAllow }))
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/server-hosting/' + id)

            if (response.status === 200) {
                loadDomain()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    const nextPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasNextPage)
            searchTempFilter = { ...searchTempFilter, page: pages === -1 ? meta.pageCount : meta.page + 1 <= meta.pageCount ? meta.page + 1 : meta.pageCount }

        loadDomain(searchTempFilter)
    }

    const prevPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasPreviousPage)
            searchTempFilter = { ...searchTempFilter, page: pages === 1 ? 1 : meta.page - 1 > 0 ? meta.page - 1 : 1 }

        loadDomain(searchTempFilter)
    }

    const changeMetaPage = (event) => {
        const newPage = event.target.value
        if (newPage <= meta.pageCount) {
            setMeta((prev) => ({
                ...prev,
                page: event.target.value
            }))
        } else {
            setMeta((prev) => ({
                ...prev,
                page: prev.pageCount
            }))
        }
    }

    const handleEnterKey = (event) => {
        // if (event.key === 'Enter') {
        //     // let searchTempFilter = { ...searchFilter }

        //     let searchTempFilter = { ...searchFilter, page: meta.page }

        //     loadCategories(searchTempFilter)
        // }
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Domain"
                    message={`Domain : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div
                className="main-content"
                id="panel">
                <div
                    className="container-fluid2 pt-3 row removable">
                    <div
                        className="card mb-4">
                        <div
                            className="card-header pb-0 subsubsub2">
                            <h6>
                                All Domains
                            </h6>
                            <span
                                className="count">
                                ({meta.itemCount | 0})
                            </span>
                        </div>
                        <div
                            className="card-body px-0 pt-0 pb-2">
                            <div
                                className="table-responsive p-0">
                                <table
                                    className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th
                                                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                BrandName</th>
                                            <th
                                                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                Domain</th>
                                            <th
                                                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                IP</th>
                                            <th
                                                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                Multiplyer</th>
                                            {/* <th
                                                className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                Status</th> */}
                                            <th
                                                className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                Date Created
                                            </th>
                                            {(userRole === "ADMIN" || userRole === "SUPER ADMIN") && (
                                                <th
                                                    className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Edit
                                                </th>
                                            )}
                                            {userRole === "SUPER ADMIN" && (<>
                                                <th
                                                    className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                    Delete
                                                </th>
                                            </>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {domains && domains.length > 0 && domains.flatMap((domain, index) => {
                                            return (
                                                <tr
                                                    key={'domain' + index}>
                                                    <td>
                                                        <div
                                                            className="d-flex px-2 py-1">
                                                            <div
                                                                className="d-flex flex-column justify-content-center">
                                                                <h6
                                                                    className="mb-0 text-sm">
                                                                    {domain.brandName}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="d-flex px-2 py-1">
                                                            <div
                                                                className="d-flex flex-column justify-content-center">
                                                                <a
                                                                    href={`http://${domain.domain}`}
                                                                    target='new'>
                                                                    <h6
                                                                        className="mb-0 text-sm">
                                                                        {domain.domain}
                                                                    </h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className="text-xs font-weight-bold mb-0">
                                                            {domain.description}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            className="text-xs font-weight-bold mb-0">
                                                            {domain.multiplyer}
                                                        </p>
                                                    </td>
                                                    {/* 
                                                    <td>
                                                        <span className={domain.webStatus ? 'online' : 'offline'}>● {domain.webStatus ? 'online' : 'offline'}</span>

                                                    </td> */}

                                                    <td
                                                        className="align-middle text-center">
                                                        <i
                                                            className="fas fa-calendar text-dark me-2"
                                                            aria-hidden="true">
                                                        </i>
                                                        <span
                                                            className="text-secondary text-xs font-weight-bold">
                                                            {formatDate(domain.createdAt)}
                                                        </span>
                                                    </td>

                                                    {(userRole === "ADMIN" || userRole === "SUPER ADMIN") && (<>
                                                        <td
                                                            className="align-middle text-center">
                                                            <a
                                                                className="btn btn-link text-dark px-3 mb-0"
                                                                href={null}
                                                                data-post-id={domain.id}
                                                                onClick={handleEdit}>
                                                                <FontAwesomeIcon icon={faPencil} />
                                                                Edit
                                                            </a>
                                                        </td>
                                                    </>)}
                                                    {userRole === "SUPER ADMIN" && (<>
                                                        <td
                                                            className="align-middle text-center">
                                                            <a
                                                                className="btn btn-link text-danger px-3 mb-0"
                                                                href={null}
                                                                data-post-id={domain.id}
                                                                data-post-value={domain.domain}
                                                                onClick={handleDelete}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                                Delete
                                                            </a>
                                                        </td>
                                                    </>)}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="tablenav-pages">
                                    <span className="displaying-num">{meta.itemCount} items</span>
                                    <span className="pagination-links">
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={() => prevPage(1)}>«</span>
                                        <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={prevPage}>‹</span>
                                        <span className="paging-input">
                                            <label htmlFor="current-page-selector" className="screen-reader-text">Current Page</label>
                                            <input className="current-page" id="current-page-selector" type="text" name="paged" defaultValue={1} size={1} value={meta && meta.page && meta.page} aria-describedby="table-paging" onChange={changeMetaPage} onKeyDown={handleEnterKey} />
                                            <span className="tablenav-paging-text"> of  <span className="total-pages">{meta.pageCount}
                                            </span>
                                            </span>
                                        </span>
                                        <a className="next-page button" onClick={nextPage}>
                                            <span className="screen-reader-text">Next page</span>
                                            <span aria-hidden="true">›
                                            </span>
                                        </a>
                                        <a className="last-page button" onClick={() => nextPage(-1)}>
                                            <span className="screen-reader-text" >Last page</span>
                                            <span aria-hidden="true">»</span>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>



                    {domainEdit.id ? (
                        <div
                            className="card mb-4">
                            <div
                                className="card-header">
                                <div
                                    className="row align-items-center">
                                    <div
                                        className="col-8">
                                        <h6
                                            className="mb-0">
                                            Add New Domain
                                        </h6>
                                        <p
                                            className="text-xs form-text text-muted3 ms-0">
                                            Type in the domain, is allow and &apos;CLICK&apos; + Add New Domain
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form-margin-top">
                                    <div>
                                        <div className="row" style={{ flexDirection: 'column' }}>
                                            <div className="col-6">
                                                <label className="form-label">BrandName</label>
                                                <input
                                                    type="text"
                                                    data-input="true"
                                                    className="form-control2 datetimepicker flatpickr-input"
                                                    placeholder="i.e: 球趣吧, 0刊球, 欢乐球"
                                                    id="brandName"
                                                    onChange={handleInputChange}
                                                    value={domainEdit.brandName ? domainEdit.brandName : ''}
                                                    {...(userRole === "ADMIN" ? { disabled: true } : {})}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Domain</label>
                                                <input type="text"
                                                    data-input="true"
                                                    className="form-control2 datetimepicker flatpickr-input"
                                                    placeholder="i.e: www.qiuquba978.com , www.0kanqiu.com, www.huanl9.com"
                                                    id="domain"
                                                    onChange={handleInputChange}
                                                    value={domainEdit.domain ? domainEdit.domain : ''}
                                                    {...(userRole === "ADMIN" ? { disabled: true } : {})}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label
                                                    className="form-label">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    data-input="true"
                                                    className="form-control2 datetimepicker flatpickr-input"
                                                    placeholder="i.e: server Host IP"
                                                    id="description"
                                                    onChange={handleInputChange}
                                                    value={domainEdit.description ? domainEdit.description : ''}
                                                    {...(userRole === "ADMIN" ? { disabled: true } : {})}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Multiplyer</label>
                                            <input
                                                min={0}
                                                type="number"
                                                data-input="true"
                                                className="form-control2 datetimepicker flatpickr-input"
                                                placeholder="1,2,3,4..."
                                                id="multiplyer"
                                                onChange={handleInputChange}
                                                value={domainEdit.multiplyer ? domainEdit.multiplyer : ''}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                className="card-header">
                                                <div
                                                    className="row align-items-center">
                                                    <div
                                                        className="col-8">
                                                        <h6
                                                            className="mb-0">
                                                            首页
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ flexDirection: 'column' }}>
                                                <div className="col-6">
                                                    <label className="form-label">Title</label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 体育足球比分_足球比分亮点_domain"
                                                        id="mainTitle"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.mainTitle ? domainEdit.mainTitle : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Description</label>
                                                    <input type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: domain 力于为您带来精彩纷呈的足球世界。从赛事报道到球队分析，从足球知识到趣味互动，我们为您呈现最全面、最深入的足球资讯。加入我们，尽情享受足球的魅力吧！  "
                                                        id="mainDesc"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.mainDesc ? domainEdit.mainDesc : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Keyword
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 足球比分,球队分析,赛事报道,体育足球,五大联赛,欧洲杯在线观看,欧洲杯赛程,欧洲杯比赛"
                                                        id="mainKey"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.mainKey ? domainEdit.mainKey : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className="card-header">
                                                <div
                                                    className="row align-items-center">
                                                    <div
                                                        className="col-8">
                                                        <h6
                                                            className="mb-0">
                                                            联赛动态
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ flexDirection: 'column' }}>
                                                <div className="col-6">
                                                    <label className="form-label">Title</label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 最新足球动态_足球新闻_domain"
                                                        id="leagueTitle"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.leagueTitle ? domainEdit.leagueTitle : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Description</label>
                                                    <input type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 掌握最新足球新闻和动态，包括英超积分榜、中超积分榜、欧洲杯比分等关键信息。我们为您提供详尽的赛程、球队排名和积分榜，确保您全面了解足球界的最新发展。 "
                                                        id="leagueDesc"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.leagueDesc ? domainEdit.leagueDesc : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Keyword
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 足球新闻,足球动态,英超联赛,西甲联赛,意甲联赛,中超联赛,积分榜"
                                                        id="leagueKey"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.leagueKey ? domainEdit.leagueKey : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className="card-header">
                                                <div
                                                    className="row align-items-center">
                                                    <div
                                                        className="col-8">
                                                        <h6
                                                            className="mb-0">
                                                            国际赛事
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row"
                                                style={{ flexDirection: 'column' }}>
                                                <div
                                                    className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 全足球大赛事资讯与新闻动态_domain"
                                                        id="intTitle"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.intTitle ? domainEdit.intTitle : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Description
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 探索足球赛事资讯，包括世界杯、欧冠、欧洲杯、亚冠等国内外联赛和杯赛的最新动态。我们为您提供详尽的足球新闻和分析，帮助您全面了解足球赛事的全貌和关键发展。 "
                                                        id="intDesc"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.intDesc ? domainEdit.intDesc : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Keyword
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: ,赛事资讯,足球资讯,世界杯,欧冠,欧洲杯比分,亚冠,国际友谊赛,足球新闻"
                                                        id="intKey"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.intKey ? domainEdit.intKey : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className="card-header">
                                                <div
                                                    className="row align-items-center">
                                                    <div
                                                        className="col-8">
                                                        <h6
                                                            className="mb-0">
                                                            足球变动
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ flexDirection: 'column' }}>
                                                <div className="col-6">
                                                    <label className="form-label">Title</label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 相关球员转会_教练_裁判等足球界动态资讯_domain"
                                                        id="footTitle"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.footTitle ? domainEdit.footTitle : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Description</label>
                                                    <input type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 获取足球最新动态，包括英超、中超、西甲、意甲及国足的转会消息和转会名单。掌握关于裁判决策、球员转会以及教练新闻的深度报道。我们为您提供权威的足球界动态和内幕消息，确保您不错过任何关键信息。 "
                                                        id="footDesc"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.footDesc ? domainEdit.footDesc : ''}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <label
                                                        className="form-label">
                                                        Keyword
                                                    </label>
                                                    <input
                                                        type="text"
                                                        data-input="true"
                                                        className="form-control2 datetimepicker flatpickr-input"
                                                        placeholder="i.e: 裁判马宁,转会消息,球员转会,英超转会消息, 西甲转会,中超转会,教练新闻,欧洲杯赛事"
                                                        id="footKey"
                                                        onChange={handleInputChange}
                                                        value={domainEdit.footKey ? domainEdit.footKey : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="MuiBox-root css-viexu0">
                                            <h6
                                                className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                            <button
                                                className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5"
                                                tabIndex="0"
                                                type="button"
                                                style={{ backgroundColor: domainEdit.id ? '#82d616' : 'rgb(68 197 255)' }}
                                                onClick={handleCreateUpdateDomain}>
                                                {/* btn mb-0 bg-gradient-info btn-md null null mb-0 */}
                                                {domainEdit.id ?
                                                    (<>
                                                        <span
                                                            className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa "
                                                            aria-hidden="true">
                                                        </span>
                                                        Update Domain
                                                    </>) :
                                                    (<>
                                                        <span
                                                            className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa"
                                                            aria-hidden="true">
                                                            add
                                                        </span>
                                                        Add New Domain
                                                    </>)
                                                }
                                            </button>
                                            <p />
                                            {domainEdit.id && (
                                                <button
                                                    className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5"
                                                    tabIndex="0"
                                                    type="button"
                                                    style={{ backgroundColor: 'gray' }}
                                                    onClick={handleCancelEditDomain}>
                                                    Cancel
                                                </button>
                                            )}

                                        </div>
                                    </div>

                                    {/* <!-- Footer --> */}

                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                                </form>
                            </div>
                        </div>)
                        :
                        (userRole === "SUPER ADMIN" && (
                            <div className="card mb-4">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h6 className="mb-0">Add New Domain</h6>
                                            <p className="text-xs form-text text-muted3 ms-0">Type in the domain, is allow and &apos;CLICK&apos; + Add New Domain</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="form-margin-top">
                                        <div>
                                            <div className="row" style={{ flexDirection: 'column' }}>
                                                <div className="col-6">
                                                    <label className="form-label">BrandName</label>
                                                    <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 球趣吧, 0刊球, 欢乐球" id="brandName" onChange={handleInputChange} value={domainEdit.brandName ? domainEdit.brandName : ''} />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Domain</label>
                                                    <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: www.qiuquba978.com , www.0kanqiu.com, www.huanl9.com" id="domain" onChange={handleInputChange} value={domainEdit.domain ? domainEdit.domain : ''} />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Description</label>
                                                    <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: server Host IP" id="description" onChange={handleInputChange} value={domainEdit.description ? domainEdit.description : ''} />
                                                </div>
                                            </div>

                                            <div>
                                                <div
                                                    className="card-header">
                                                    <div
                                                        className="row align-items-center">
                                                        <div
                                                            className="col-8">
                                                            <h6
                                                                className="mb-0">
                                                                首页
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ flexDirection: 'column' }}>
                                                    <div className="col-6">
                                                        <label className="form-label">Title</label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 体育足球比分_足球比分亮点_domain"
                                                            id="mainTitle"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.mainTitle ? domainEdit.mainTitle : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">Description</label>
                                                        <input type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: domain 力于为您带来精彩纷呈的足球世界。从赛事报道到球队分析，从足球知识到趣味互动，我们为您呈现最全面、最深入的足球资讯。加入我们，尽情享受足球的魅力吧！  "
                                                            id="mainDesc"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.mainDesc ? domainEdit.mainDesc : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Keyword
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 足球比分,球队分析,赛事报道,体育足球,五大联赛,欧洲杯在线观看,欧洲杯赛程,欧洲杯比赛"
                                                            id="mainKey"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.mainKey ? domainEdit.mainKey : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div
                                                    className="card-header">
                                                    <div
                                                        className="row align-items-center">
                                                        <div
                                                            className="col-8">
                                                            <h6
                                                                className="mb-0">
                                                                联赛动态
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ flexDirection: 'column' }}>
                                                    <div className="col-6">
                                                        <label className="form-label">Title</label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 最新足球动态_足球新闻_domain"
                                                            id="leagueTitle"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.leagueTitle ? domainEdit.leagueTitle : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">Description</label>
                                                        <input type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 掌握最新足球新闻和动态，包括英超积分榜、中超积分榜、欧洲杯比分等关键信息。我们为您提供详尽的赛程、球队排名和积分榜，确保您全面了解足球界的最新发展。 "
                                                            id="leagueDesc"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.leagueDesc ? domainEdit.leagueDesc : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Keyword
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 足球新闻,足球动态,英超联赛,西甲联赛,意甲联赛,中超联赛,积分榜"
                                                            id="leagueKey"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.leagueKey ? domainEdit.leagueKey : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div
                                                    className="card-header">
                                                    <div
                                                        className="row align-items-center">
                                                        <div
                                                            className="col-8">
                                                            <h6
                                                                className="mb-0">
                                                                国际赛事
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="row"
                                                    style={{ flexDirection: 'column' }}>
                                                    <div
                                                        className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 全足球大赛事资讯与新闻动态_domain"
                                                            id="intTitle"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.intTitle ? domainEdit.intTitle : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Description
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 探索足球赛事资讯，包括世界杯、欧冠、欧洲杯、亚冠等国内外联赛和杯赛的最新动态。我们为您提供详尽的足球新闻和分析，帮助您全面了解足球赛事的全貌和关键发展。 "
                                                            id="intDesc"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.intDesc ? domainEdit.intDesc : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Keyword
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: ,赛事资讯,足球资讯,世界杯,欧冠,欧洲杯比分,亚冠,国际友谊赛,足球新闻"
                                                            id="intKey"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.intKey ? domainEdit.intKey : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div
                                                    className="card-header">
                                                    <div
                                                        className="row align-items-center">
                                                        <div
                                                            className="col-8">
                                                            <h6
                                                                className="mb-0">
                                                                足球变动
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ flexDirection: 'column' }}>
                                                    <div className="col-6">
                                                        <label className="form-label">Title</label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 相关球员转会_教练_裁判等足球界动态资讯_domain"
                                                            id="footTitle"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.footTitle ? domainEdit.footTitle : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">Description</label>
                                                        <input type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 获取足球最新动态，包括英超、中超、西甲、意甲及国足的转会消息和转会名单。掌握关于裁判决策、球员转会以及教练新闻的深度报道。我们为您提供权威的足球界动态和内幕消息，确保您不错过任何关键信息。 "
                                                            id="footDesc"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.footDesc ? domainEdit.footDesc : ''}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label
                                                            className="form-label">
                                                            Keyword
                                                        </label>
                                                        <input
                                                            type="text"
                                                            data-input="true"
                                                            className="form-control2 datetimepicker flatpickr-input"
                                                            placeholder="i.e: 裁判马宁,转会消息,球员转会,英超转会消息, 西甲转会,中超转会,教练新闻,欧洲杯赛事"
                                                            id="footKey"
                                                            onChange={handleInputChange}
                                                            value={domainEdit.footKey ? domainEdit.footKey : ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="MuiBox-root css-viexu0">
                                                <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                                <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: domainEdit.id ? '#82d616' : 'rgb(68 197 255)' }} onClick={handleCreateUpdateDomain}>

                                                    {/* btn mb-0 bg-gradient-info btn-md null null mb-0 */}
                                                    {domainEdit.id ?
                                                        (<>
                                                            <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa " aria-hidden="true"></span>Update Domain
                                                        </>) :
                                                        (<>
                                                            <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>Add New Domain
                                                        </>)
                                                    }
                                                </button>
                                                <p></p>
                                                {domainEdit.id && (
                                                    <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: 'gray' }} onClick={handleCancelEditDomain}>
                                                        Cancel
                                                    </button>
                                                )}

                                            </div>
                                        </div>

                                        {/* <!-- Footer --> */}

                                        <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                        <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                                    </form>
                                </div>
                            </div>))
                    }


                </div >
            </div >
        </>
    )
}
Domain.propTypes = {
    userRole: PropTypes.string,
};

