import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCog } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/action/actions";
import { useNavigate } from "react-router-dom";

export default function Header() {
    const [isLogin, setIsLogin] = useState(false)
    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const redirect = useNavigate();
    useEffect(() => {
        setIsLogin(user.token ? true : sessionStorage.getItem('token') ? true : false)
        handleRole()
    }, [])

    const handleRole = async () => {
        if (!sessionStorage.getItem('token')) {
            dispatch(logOut())
            // window.location.href = '/login'
            redirect('/login')
        }
    }

    const handleLogout = async () => {
        try {
            sessionStorage.clear();
            // window.location.href = '/login'
            dispatch(logOut())
            redirect('/login')
        } catch (error) {
            console.error("An error occurred during login:", error);
        }
    }

    return (
        <nav className="navbar navbar-expand-lg px-0 mx-4x mt-3 shadow-none border-radius-xl" id="navbarTop" data-navbar="true" data-navbar-value={49}>
            <div className="container-fluid2 py-1 px-3">

                <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                    </div>
                    <ul className="navbar-nav justify-content-end">
                        <li className="nav-item d-flex align-items-center">
                            <a onClick={handleLogout}><button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall css-11wlow9" tabIndex={0} type="button" onClick={handleLogout}><span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-15v3pu7" aria-hidden="true">account_circle</span><span className="MuiTypography-root MuiTypography-button css-1w3klud">{isLogin ? "Sign Out" : "Sign in"}</span></button></a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                            <a href={undefined} className="nav-link text-body p-0">
                                {/* <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer" aria-hidden="true" /> */}
                                <FontAwesomeIcon icon={faCog} />
                            </a>
                        </li>
                        {/* <li className="nav-item dropdown pe-2 d-flex align-items-center">
                            <a href={undefined} className="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faBell} />
                            </a>
                             <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href={undefined}>
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="https://demos.creative-tim.com/soft-ui-dashboard/assets/img/team-2.jpg" className="avatar avatar-sm me-3 " />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-xxs font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New Posts</span> from 黑猫
                                                </h6>
                                                <p className="text-xxs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true" />
                                                    13 minutes ago
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href={undefined}>
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="https://demos.creative-tim.com/soft-ui-dashboard/assets/img/team-4.jpg" className="avatar avatar-sm bg-gradient-dark me-3 " />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-xxs font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New Pages</span> from 大头
                                                </h6>
                                                <p className="text-xxs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true" />
                                                    1 day
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item border-radius-md" href={undefined}>
                                        <div className="d-flex py-1">
                                            <div className="avatar avatar-sm bg-gradient-secondary me-3 my-auto">
                                                <i className="ni ni-settings text-dark text-gradient text-lg top-0" aria-hidden="true" id="sidenavCardIcon" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-xxs font-weight-normal mb-1">
                                                    Setting successfully completed by 小潘
                                                </h6>
                                                <p className="text-xxs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true" />
                                                    2 days
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul> 
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
