import React from "react";
import PropTypes from 'prop-types'
const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {

    const handleClick = () => {
        setActiveTab(id);
    };

    return (
        <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
            {title}
        </li>
    );
};

TabNavItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    activeTab: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired
};

export default TabNavItem;