import React, { useState, useEffect } from "react";
import SideMenu from '../component/side-menu/side-menu'
import Header from '../component/header/header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../instance/api-client";


export default function Dashboard() {

    const [domains, setDomains] = useState([])

    useEffect(() => {
        loadDomain()
    }, [])

    const loadDomain = async () => {
        const response = await apiClient.get('server-hosting?take=9')

        if (response.data)
            setDomains(response.data.data)
    }

    // const chartSetting = {
    //     yAxis: [
    //         {
    //             label: '',
    //         },
    //     ],
    //     width: 600,
    //     height: 170,
    // };
    // const dataset = [
    //     {
    //         london: 59,
    //         paris: 57,
    //         newYork: 86,
    //         seoul: 21,
    //         month: 'Jan',
    //     },
    //     {
    //         london: 50,
    //         paris: 52,
    //         newYork: 78,
    //         seoul: 28,
    //         month: 'Fev',
    //     },
    //     {
    //         london: 47,
    //         paris: 53,
    //         newYork: 106,
    //         seoul: 41,
    //         month: 'Mar',
    //     },
    //     {
    //         london: 54,
    //         paris: 56,
    //         newYork: 92,
    //         seoul: 73,
    //         month: 'Apr',
    //     },
    //     {
    //         london: 57,
    //         paris: 69,
    //         newYork: 92,
    //         seoul: 99,
    //         month: 'May',
    //     },
    //     {
    //         london: 60,
    //         paris: 63,
    //         newYork: 103,
    //         seoul: 144,
    //         month: 'June',
    //     },
    //     {
    //         london: 59,
    //         paris: 60,
    //         newYork: 105,
    //         seoul: 319,
    //         month: 'July',
    //     },
    //     {
    //         london: 65,
    //         paris: 60,
    //         newYork: 106,
    //         seoul: 249,
    //         month: 'Aug',
    //     },
    //     {
    //         london: 51,
    //         paris: 51,
    //         newYork: 95,
    //         seoul: 131,
    //         month: 'Sept',
    //     },
    //     {
    //         london: 60,
    //         paris: 65,
    //         newYork: 97,
    //         seoul: 55,
    //         month: 'Oct',
    //     },
    //     {
    //         london: 67,
    //         paris: 64,
    //         newYork: 76,
    //         seoul: 48,
    //         month: 'Nov',
    //     },
    //     {
    //         london: 61,
    //         paris: 70,
    //         newYork: 103,
    //         seoul: 25,
    //         month: 'Dec',
    //     },
    // ];

    // const valueFormatter = (value) => `${value}mm`;


    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }
    return (
        <>
            <SideMenu />
            <Header />
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">

                    <div className="mb-4">
                        <div className="col-12">
                            <div className="">
                                <div className="pb-0">
                                    {/* <!-- Start Main Content --> */}


                                    <div className="row my-4">
                                        <div className="col-lg-8 col-md-6 mb-md-0 mb-4">
                                            <div className="card">
                                                <div className="card-header pb-0">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-7">
                                                            <h6>Hosted Domain</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body px-0 pb-2">
                                                    <div className="table-responsive">
                                                        <table className="table align-items-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">BrandName</th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Domain</th>
                                                                    {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th> */}
                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">CreatedAt</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {domains && domains.length > 0 && domains.flatMap((domain, index) => {
                                                                    return (
                                                                        <tr key={'domain' + index}>
                                                                            <td>
                                                                                <div className="d-flex px-2 py-1">
                                                                                    <div className="d-flex flex-column justify-content-center">
                                                                                        <h6 className="mb-0 text-sm">{domain.brandName}</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex px-2 py-1">
                                                                                    <div className="d-flex flex-column justify-content-center">
                                                                                        <a href={`http://${domain.domain}`} target='new'>
                                                                                            <h6 className="mb-0 text-sm">{domain.domain}</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>


                                                                            {/* <td>
                                                                                <span className={domain.webStatus ? 'online' : 'offline'}>● {domain.webStatus ? 'online' : 'offline'}</span>

                                                                            </td> */}

                                                                            <td className="align-middle text-center">
                                                                                <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                                                                                <span className="text-secondary text-xs font-weight-bold">{formatDate(domain.createdAt)}</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4 col-md-6">
                                            <div className="card h-100">
                                                <div className="card-header pb-0">
                                                    <h6>Activity Log</h6>
                                                    <p className="text-sm">
                                                        <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>
                                                        <span className="font-weight-bold">24%</span> this month
                                                    </p>
                                                </div>
                                                <div className="card-body p-3">
                                                    <div className="timeline-one-side" style={{ position: 'relative' }}>
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-bell-55 text-success text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">EPL posts changes</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">22 March 3:20 PM</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-html5 text-danger text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">Add New User</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">21 March 11 AM</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni- text-info text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">Server Configurations</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">21 March 9:34 PM</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-credit-card text-warning text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">New card added for posts #13133</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">20 March 2:20 PM</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-block mb-3">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-key-25 text-primary text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">Unlock packages for development</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">18 March 4:54 PM</p>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-block">
                                                            <span className="timeline-step">
                                                                <i className="ni ni-money-coins text-dark text-gradient"></i>
                                                            </span>
                                                            <div className="timeline-content">
                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">New page #3120</h6>
                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">17 DEC 1:20 PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}