import apiClient from "instance/api-client";

export default async function processMultiCreatableSelect(details , type) {
    let newDetailFull = [];
    let newDetailIdOnly = [];

    if (details) {
        details.forEach(detail => {
            if (detail.id) {
                newDetailFull.push(detail);
                newDetailIdOnly.push(detail.id);
            }
        });

        const tagFilter = details.filter(detail => detail.__isNew__)
            .map(detail => ({ name: detail.label }));

        if (tagFilter.length !== 0) {
            const result = await apiClient.bulkCreate(tagFilter, type);

            if (result) {
                result.data.forEach(detail => {
                    newDetailFull.push({ ...detail, label: detail.name, value: detail.id });
                    newDetailIdOnly.push(detail.id);
                });
            }
        }

        return { full: newDetailFull, idOnly: newDetailIdOnly };
    }
}