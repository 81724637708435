import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../../component/side-menu/side-menu"
import Header from "../../component/header/header"
import apiClient from "../../instance/api-client";
import DeletePrompt from "../../component/prompt/delete-prompt";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function Tags() {
  const userRole = useSelector(state => state.user.role)
  const [hashTags, setHashTags] = useState([])
  const [hashTagEdit, setHashTagEdit] = useState({
    id: null,
    name: '',
    slug: '',
    description: ''
  })
  const [deleteHook, setDeleteHook] = useState({
    show: false,
    id: null,
    value: null,
  })

  useEffect(() => {
    loadHashTags()
  }, [])

  const loadHashTags = async () => {
    try {
      const response = await apiClient.get('admin/hash-tags')

      if (response.status === 200) {

        setHashTags(response.data)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  const handleEdit = (event) => {
    const hashTagId = event.currentTarget.getAttribute('data-post-id');
    const edit = hashTags.filter((hashTag) => hashTag.id == hashTagId)
    if (edit.length > 0) {
      setHashTagEdit({ id: edit[0].id, slug: edit[0].slug, description: edit[0].description, name: edit[0].name })
    }
  }

  const handleDelete = (event) => {

    const tagId = event.currentTarget.getAttribute('data-post-id');
    const tagValue = event.currentTarget.getAttribute('data-post-value');
    setDeleteHook({
      show: true,
      id: tagId,
      value: tagValue
    })
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const handleCreateUpdateHashTag = async () => {
    let response
    try {
      if (hashTagEdit.name) {
        if (hashTagEdit.id)
          response = await apiClient.patch('admin/hash-tags/' + hashTagEdit.id, hashTagEdit)
        else
          response = await apiClient.post('admin/hash-tags', hashTagEdit)

        if (response.status === 200) {
          handleCancelEditHashTag()
          loadHashTags()
        }
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  }

  const handleCancelEditHashTag = () => {
    setHashTagEdit({ id: null, slug: '', description: '', name: '' })
  }

  const handleInputChange = (event) => {
    const id = event.target.id;
    setHashTagEdit((prev) => ({
      ...prev,
      [id]: event.target.value
    }));
  }

  const onActionDialogClose = () => {
    setDeleteHook((prev) => ({ ...prev, show: false }))
  }

  const onActionDialogComplete = async (id) => {
    await apiClient.delete('admin/hash-tags/' + id)
    loadHashTags()
    onActionDialogClose()
  }

  return (
    <>
      <SideMenu />
      <Header />
      {deleteHook.show && (
        <DeletePrompt
          title="Tags"
          message={`Tag Name : ${deleteHook.value}`}
          id={deleteHook.id}
          handleDeletePrompt={onActionDialogComplete}
          handleClosePrompt={onActionDialogClose}
        />
      )}

      <div className="main-content" id="panel">
        <div className="container-fluid2 pt-3 row removable">
          <div className="card mb-4">
            <div className="card-header pb-0 subsubsub2">
              <h6>All Tags</h6>
              <span className="count">(5)</span>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th key={"name"} className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th key={"Slug"} className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Slug</th>
                      <th key={"Descriptions"} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descriptions</th>
                      <th key={"Date Created"} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Created</th>
                      {(userRole === "SUPER ADMIN" && userRole === "ADMIN") && (<>
                        <th key={"Edit"} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Edit</th>
                        <th key={"Delete"} className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Delete</th>
                      </>)}
                    </tr>
                  </thead>
                  <tbody>
                    {hashTags && hashTags.flatMap((tag, index) => {
                      return (
                        <tr key={'tags' + index}>
                          <td>
                            <div className="d-flex px-2 py-1">
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="mb-0 text-sm">{tag.name}</h6>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="text-xs font-weight-bold mb-0">{tag.slug}</p>
                          </td>
                          <td>
                            <p className="text-xs font-weight-bold mb-0">{tag.description}</p>
                          </td>

                          <td className="align-middle text-center">
                            <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                            <span className="text-secondary text-xs font-weight-bold">{formatDate(tag.createdAt)}</span>
                          </td>
                          {(userRole === "SUPER ADMIN" && userRole === "ADMIN") && (<>
                            <td className="align-middle text-center">
                              <a className="btn btn-link text-dark px-3 mb-0" href={null} data-post-id={tag.id} onClick={handleEdit}><FontAwesomeIcon icon={faPencil} /> Edit</a>
                            </td>
                            <td className="align-middle text-center">
                              <a className="btn btn-link text-danger px-3 mb-0" href={null} data-post-id={tag.id} data-post-value={tag.name} onClick={handleDelete}>  <FontAwesomeIcon icon={faTrash} /> Delete</a>
                            </td>
                          </>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {(userRole === "SUPER ADMIN" && userRole === "ADMIN") && (<div className="card mb-4">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-8">
                  <h6 className="mb-0">Add New Tag</h6>
                  <p className="text-xs form-text text-muted3 ms-0">Type in the name, slug and descriptions and &apos;CLICK&apos; + Add New Tag</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <form className="form-margin-top">
                <div>
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Name</label>
                      <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="name" onChange={handleInputChange} value={hashTagEdit.name} />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Slug</label>
                      <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: worldcup, chelsea, ac-milan, christiano-ronaldo" id="slug" onChange={handleInputChange} value={hashTagEdit.slug} />
                    </div>
                    <div className="col-12">
                      <label className="form-label">Descriptions</label>
                      <input type="text" data-input="true" className="form-control2" placeholder="i.e: *English Premier Leagues fixture, livescore, standings and player transfer related topic. (*Optional-You may leave it blank as well.)" id="description" onChange={handleInputChange} value={hashTagEdit.description} />
                    </div>
                  </div>

                  <div className="MuiBox-root css-viexu0">
                    <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                    <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: hashTagEdit.id ? '#82d616' : 'rgb(68 197 255)' }} onClick={handleCreateUpdateHashTag}>
                      {hashTagEdit.id ?
                        (<>
                          <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true"></span>Update Category
                        </>) :
                        (<>
                          <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>Add New Category
                        </>)
                      }
                    </button>
                    <p></p>
                    {hashTagEdit.id && (
                      <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: 'gray' }} onClick={handleCancelEditHashTag}>
                        Cancel
                      </button>
                    )}

                  </div>
                </div>
              </form>
            </div>
          </div>)}



          {/* <!-- Footer --> */}
        </div>
      </div>
    </>
  )
}

Tags.propTypes = {
  userRole: PropTypes.string,
};

