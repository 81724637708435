import React from 'react';
import PropTypes from 'prop-types';

Thumbnail.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    uploadBy: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    fileSize: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default function Thumbnail(props) {
    const { src, alt, uploadBy, uploadDate, fileName, fileSize, onClick } = props


    return (
        <div className="col-sm-3 thumb" style={{ backgroundImage: `url(${src})` }} data-src={src} alt={alt}>
            <a data-toggle="modal" data-target="#myModal" data-uploadby={uploadBy} data-info={`Upload on: ${uploadDate}`} data-filename={`File: ${fileName}`} data-filesize={`File size: ${fileSize}`} data-input="" data-alt={`alt: ${alt}`} onClick={() => onClick(props)}></a>

        </div>
    );
}