import React, { useEffect, useState } from 'react'
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import apiClient from "../../instance/api-client";
import CreatableSelect from 'react-select/creatable';

export default function AllSubPages() {
    const [pages, setPages] = useState(null)
    const [subPages, setSubPages] = useState(null)
    const [subPageEdit, setSubPageEdit] = useState([])
    const [selected, setSelected] = useState({
        pageId: 0,
        categoryId: null
    })

    useEffect(() => {
        loadPages()
        loadSubPages()
    }, [])

    const loadPages = async () => {
        try {
            const response = await apiClient.get('admin/pages')
            if (response.status === 200) {
                setPages(response.data)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const loadSubPages = async () => {
        try {
            const response = await apiClient.get('admin/categories',{params:{take:99999}})
            if (response.status === 200) {
                setSubPages(response.data.data.flatMap((object) => { return { ...object, label: object.name, value: object.id } }))
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleCreateSubPage = async () => {

        let subPageTempEdit = { ...subPageEdit }
        if (subPageTempEdit.__isNew__) {
            const result = await apiClient.bulkCreate([{ ...subPageTempEdit, name: subPageTempEdit.label }], "Category")

            if (result)
                subPageTempEdit = { ...subPageTempEdit, categoryId: result.data[0].id }
        }

        subPageTempEdit = { ...selected, ...subPageTempEdit }

        if (subPageTempEdit.pageId && subPageTempEdit.categoryId) {
            const response = await apiClient.post('admin/sub-pages', { ...subPageTempEdit })

            if (response.data.statusCode === 200) {
                loadPages()
            }
        }

    }

    const handlePageOnChange = (event) => {
        setSelected((prev) => ({
            ...prev,
            pageId: +event.target.value
        }))
    }

    const handleSubPagesOnChange = (subPageCategory) => {
        setSubPageEdit(subPageCategory)
        setSelected((prev) => ({
            ...prev,
            categoryId: subPageCategory.id
        }))
    }

    return (
        <>
            <SideMenu />
            <Header />
            <div className="g-sidenav-show">
                <div className="main-content" id="panel">
                    <div className="container-fluid2 pt-3 row removable">
                        <div className="card mb-4">
                            <div className="card-header pb-0">
                                <h6>Sitemap</h6>
                            </div>

                            <ul id="myUL" className="treeview">
                                {pages && pages.length > 0 && pages.flatMap((page, index) => {
                                    return (
                                        <div key={'page' + index}>
                                            <li key={'pages' + index}> {page.categories.name}</li>
                                            <ul className="inner_ul">
                                                {page.subPage && page.subPage.length > 0 && page.subPage.flatMap((subPage, index) => {
                                                    return (
                                                        <div key={'subPage' + index}>
                                                            <li>
                                                                {subPage.categories.name}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className="card mb-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h6 className="mb-0">Add New Pages</h6>
                                        <p className="text-xs text-instructions form-text text-muted3 ms-0">Assign your sub pages to your desirable main page and type in your sub page title, &apos;CLICK&apos; + Add New Sub Pages</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form-margin-top">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <label>Assign to</label>
                                            <div className="form-group multisteps-form__input">
                                                <select id="company" className="form-control2 form-control-default" onChange={handlePageOnChange} value={selected.pageId}>
                                                    <option key={'pageKey' + 0} id={0} value={null}>Please Select</option>
                                                    {pages && pages.length > 0 && pages.flatMap((page) => {
                                                        return (
                                                            <option key={'pageKey' + page.id} id={page.id} value={page.id}>{page.categories.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <label>Sub Page Title</label>
                                            <div className="form-group multisteps-form__input">
                                                <CreatableSelect options={subPages} value={subPageEdit} onChange={handleSubPagesOnChange} />
                                            </div>
                                        </div>

                                        <div className="MuiBox-root css-viexu0">
                                            <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                            <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" onClick={handleCreateSubPage}>
                                                <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>&nbsp;add new sub pages
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}