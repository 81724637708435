import React from "react"
import PropTypes from 'prop-types'



class DeletePrompt extends React.Component {

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }
    render() {
        const { title, message, id, handleDeletePrompt, handleClosePrompt } = this.props;

        return (
            <div className="dialog" onClick={handleClosePrompt}>
                <div className="content">
                    <p>are you sure to delete {title} ?</p>
                    <p>{message} </p>
                    <div className="button-content ">
                        <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" onClick={() => handleDeletePrompt(id)}>Delete</button>
                        <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" onClick={handleClosePrompt}>Cancel</button>
                    </div>
                </div>

            </div>
        );
    }
}

DeletePrompt.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    id: PropTypes.number.isRequired,
    handleDeletePrompt: PropTypes.func.isRequired,
    handleClosePrompt: PropTypes.func.isRequired
};

export default DeletePrompt;