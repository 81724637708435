import { React, useEffect, useState } from "react";
// import axios from "axios";
import './login.css';
import apiClient from "../instance/api-client";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../redux/action/actions";
import { useNavigate } from "react-router-dom";


export default function Login() {
    const redirect = useNavigate();
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const [userLogin, setUserLogin] = useState({
        username: '',
        password: ''
    });

    useEffect(() => {
        if (user.token) {
            redirect('/AllPost')
        }
    }, [])

    const handleLogin = async () => {
        try {
            const response = await apiClient.post('/auth/login', userLogin)
            if (response.data.statusCode === 200) {
                sessionStorage.setItem('token', response.data.access_token);
                dispatch(setUser(response.data.role, response.data.access_token))
                redirect('/AllPost')
            }

            // save to storage
        } catch (error) {
            console.error("An error occurred during login:", error);
        }
    }

    const handleInput = (event) => {
        if (event.target.id === 'username') {
            setUserLogin((preUserLogin) => ({ ...preUserLogin, username: event.target.value }))
        }

        if (event.target.id === 'password') {
            setUserLogin((preUserLogin) => ({ ...preUserLogin, password: event.target.value }))
        }
    }

    return (
        <div className="login">
            <section style={{ width: '500px !important' }}>
                <div  >
                    <img src={"/images/logo-multisite.png"} style={{ width: '300px' }} />

                    <h1>登录</h1>
                    <div className="inputbox">
                        <ion-icon name="person-circle-outline" role="img" className="md hydrated"></ion-icon>
                        <input type="text" id="username" name="username" onChange={handleInput} required />
                        <label>用户名</label>
                    </div>
                    <div className="inputbox">
                        <ion-icon name="lock-closed-outline" role="img" className="md hydrated"></ion-icon>
                        <input type="password" id="password" name="password" onChange={handleInput} required />
                        <label>密码</label>
                    </div>
                    <div className="forget">
                        <label><input type="checkbox" />记住账号</label>
                    </div>
                    <button onClick={handleLogin}>登录</button>
                </div>

            </section>
        </div>
    )
}
