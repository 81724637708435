import React, { useState, useEffect } from "react";
import SideMenu from '../component/side-menu/side-menu'
import Header from '../component/header/header'
import apiClient from "../instance/api-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFileAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import DeletePrompt from "component/prompt/delete-prompt";


export default function User() {
    const userRole = useSelector(state => state.user.role)
    // const [userCount, setUserCount] = useState(0)
    const [users, setUsers] = useState([])
    const [searchUserName, setSearchUserName] = useState(null)
    const [meta, setMeta] = useState([])
    const [userEdit, setUserEdit] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'ADMIN'
    })
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState({
        show: false,
        message: '',
        status: false
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = async (searchFilter) => {

        try {
            const response = await apiClient.get('admin/users', { params: searchFilter })
            if (response.status === 200) {
                setUsers(response.data.data)
                setMeta(response.data.meta)
            }
        } catch (error) {
            console.info(error)
        }

    }

    // const handleFilter = () => {
    // }

    const handleOnChangeSearchName = (event) => {
        const userName = event.target.value
        setSearchUserName(userName)

    }

    const handleSearchUserName = () => {
        loadUser({ name: searchUserName })

    }

    // const formatDate = (dateString) => {
    //     const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
    //     return new Date(dateString).toLocaleDateString(undefined, options)
    // }

    // const handleEditUser = (event) => {
    //     const userId = event.currentTarget.getAttribute('data-user-id');
    // }

    const handleDeleteUser = (event) => {
        const userId = event.currentTarget.getAttribute('data-user-id');
        const userValue = event.currentTarget.getAttribute('data-user-value');
        setDeleteHook({
            show: true,
            id: userId,
            value: userValue,
        })
    }

    // const handlePreviewUser = (event) => {
    //     const userId = event.currentTarget.getAttribute('data-user-id');
    // }

    const nextPage = (pages) => {
        let searchTempFilter = {}

        if (meta.hasNextPage)
            searchTempFilter = { ...searchTempFilter, page: pages === -1 ? meta.pageCount : meta.page + 1 <= meta.pageCount ? meta.page + 1 : meta.pageCount }

        loadUser(searchTempFilter)
    }

    const prevPage = (pages) => {
        let searchTempFilter = {}

        if (meta.hasPreviousPage)
            searchTempFilter = { ...searchTempFilter, page: pages === 1 ? 1 : meta.page - 1 > 0 ? meta.page - 1 : 1 }

        loadUser(searchTempFilter)
    }


    // create user input field
    const handleOnChange = (event) => {
        const { id, value } = event.target;
        setUserEdit((prev) => ({
            ...prev,
            [id]: value
        }));

        // Clear previous errors for the field
        setErrors(prevErrors => ({
            ...prevErrors,
            [id]: ''
        }));
    }

    const handleCreateUpdateUser = () => {
        // Validation logic
        const errors = {};
        if (!userEdit.name) {
            errors.name = 'Name is required';
        }
        if (!userEdit.username) {
            errors.username = 'Username is required';
        }
        if (!userEdit.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(userEdit.email)) {
            errors.email = 'Invalid email address';
        }
        if (!userEdit.password) {
            errors.password = 'Password is required';
        } else if (userEdit.password.length < 8) {
            errors.password = 'Password must be at least 8 characters long';
        }
        if (userEdit.password !== userEdit.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        // Set errors if any
        setErrors(errors);

        // If there are no errors, proceed with submitting the form
        if (Object.keys(errors).length === 0) {
            // Submit the form
            // Example: You can make an API call here
            createUser(userEdit)
            console.log('Form submitted:', userEdit);
        } else {
            // Handle errors
            console.log('Form contains errors:', errors);
        }
    }

    const createUser = async (user) => {
        try {
            const response = await apiClient.post('public/users/create', user);
            if (response.status === 201) {
                // alert(`success create user: ${JSON.stringify(user)}`);
                setAlert({ message: `success create User : ${user.name} Role : ${user.role}`, show: true, status: true })
                loadUser()
            }
        } catch (error) {
            // Handle client-side errors (e.g., network issues)
            // console.error('Error:', error.response.data.message);
            if (error.response.data.message.includes('email')) {
                setErrors({ email: error.response.data.message });
            }

            if (error.response.data.message.includes('username')) {
                setErrors({ username: error.response.data.message });
            }
            setAlert({ message: error.response.data.message, show: true, status: false })
            // alert(error.response.data.message)
        }
    }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/users/' + id)

            if (response.status === 200) {
                loadUser()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const handleClose = () => {
        setAlert({ message: '', show: false })
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Categories"
                    message={`Categories : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="main-content" id="panel">
                <nav aria-label="breadcrumb">
                    <h6 className="font-weight-med mb-0">User <a href={undefined}>/ All User</a></h6>
                </nav>
                <div className="container-fluid2 pt-3 row removable">
                    <div className="">
                        {/*All User:Start*/}
                        <div className="card mb-4">
                            <div className="subsubsub ms-md-auto2 pe-md-3 d-flex2 align-items-center">
                                <ul>
                                    <span>Filter : </span>
                                    {/* {
                                        Object.entries(userCount).map(([key, countObject]) => (
                                            <li key={key}><a id={countObject.value} onClick={handleFilter} href={undefined}>{countObject.name} </a><span className="count">({countObject.count})</span> |</li>
                                        ))
                                    } */}
                                </ul>
                                <div className="input-group2 text-end">
                                    <span className="input-group-text2 text-body"><FontAwesomeIcon icon={faSearch} /></span>

                                    <input type="text" className="form-control" value={searchUserName} onChange={handleOnChangeSearchName} placeholder="username" />
                                    <div className="col-5 text-end">
                                        <a onClick={handleSearchUserName} className="btn btn-sm bg-gradient-primary mb-0" href={undefined}>Search User</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <div className="table-responsive p-0">
                                    <table className="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Name</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Username</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Email</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7 ps-2">Role</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Posts</th>
                                                {userRole === 'SUPER ADMIN' && <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Delete</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.length > 0 && users.flatMap((user) => {
                                                return (
                                                    <tr key={`key-${user.id}`}>
                                                        <td className="align-middle text-center">
                                                            <div className="d-flex px-2 py-1">
                                                                <div>
                                                                    <img src="assets/images/team-2.jpg" className="avatar avatar-sm me-3" />
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center ">
                                                                    <p className="text-xs text-secondary mb-0">{user.name}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-center">

                                                            <div className="d-flex flex-column justify-content-center ">
                                                                <p className="text-xl font-weight-bold title-wrap mb-0">{user.username}</p>
                                                            </div>

                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <p className="text-xl font-weight-bold title-wrap mb-0"><a href={undefined}>{user.email}</a></p>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <p className="text-xs font-weight-bold mb-0">{user.role}</p>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <a className="btn btn-link text-info px-3 mb-0" href={undefined}> <FontAwesomeIcon icon={faFileAlt} />32</a>
                                                        </td>
                                                        {userRole === 'SUPER ADMIN' && <td className="align-middle text-center">
                                                            <a className="btn btn-link text-danger px-3 mb-0" href={null} data-user-id={user.id} data-user-value={user.name} onClick={handleDeleteUser}>
                                                                <FontAwesomeIcon icon={faTrash} /> Delete</a>
                                                        </td>}

                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="tablenav-pages"><span className="displaying-num">{meta.itemCount} items</span>
                                        <span className="pagination-links"><span className="tablenav-pages-navspan button " aria-hidden="true" onClick={() => prevPage(1)}>«</span>
                                            <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={prevPage}>‹</span>
                                            <span className="paging-input">
                                                <label htmlFor="current-page-selector" className="screen-reader-text">Current Page</label>
                                                <input className="current-page" id="current-page-selector" type="text" name="paged" defaultValue={1} size={1} value={meta.page} aria-describedby="table-paging" onChange={null} />
                                                <span className="tablenav-paging-text"> of  <span className="total-pages">{meta.pageCount}
                                                </span>
                                                </span>
                                            </span>
                                            <a className="next-page button" onClick={nextPage}>
                                                <span className="screen-reader-text">Next page</span>
                                                <span aria-hidden="true">›
                                                </span>
                                            </a>
                                            <a className="last-page button" onClick={() => nextPage(-1)}>
                                                <span className="screen-reader-text" >Last page</span>
                                                <span aria-hidden="true">»</span>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-8">
                                                <h6 className="mb-0">Add New User</h6>
                                                <p className="text-xs text-instructions form-text text-muted3 ms-0">Type in your username and &apos;CLICK&apos; + Add New User</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0 pb-2">
                                        <form className="">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <label>Username</label>{errors.username && (<label style={{ color: 'red' }}> {errors.username}</label>)}
                                                    <div className="form-group multisteps-form__input">
                                                        <input id="username" type="email" className="form-control2 form-control-default" name="" placeholder="eg. Steven_404" value={userEdit.username} onChange={handleOnChange} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <label>Name</label>{errors.name && (<label style={{ color: 'red' }}> {errors.name}</label>)}
                                                    <div className="form-group multisteps-form__input">
                                                        <input id="name" type="email" className="form-control2 form-control-default" name="" placeholder="eg. Steven" value={userEdit.name} onChange={handleOnChange} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <label>Email</label>{errors.email && (<label style={{ color: 'red' }}> {errors.email}</label>)}
                                                    <div className="form-group multisteps-form__input">
                                                        <input id="email" type="email" className="form-control2 form-control-default" name="" placeholder="eg. steven-404@email.com" value={userEdit.email} onChange={handleOnChange} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <label>Assign to</label>
                                                    <div className="form-group multisteps-form__input">
                                                        <select id="role" className="form-control2 form-control-default" value={userEdit.role} onChange={handleOnChange}>
                                                            <option value="ADMIN">Admin</option>
                                                            <option value="EDITOR">Editor</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <label>Password</label> {errors.password && (<label style={{ color: 'red' }}> {errors.password}</label>)}
                                                    <div className="form-group multisteps-form__input">
                                                        <input id="password" type="password" className="form-control2 form-control-default" name="" placeholder="eg. Steven_404" value={userEdit.password} onChange={handleOnChange} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">
                                                    <label>Confirm Password</label>{errors.confirmPassword && (<label style={{ color: 'red' }}> {errors.confirmPassword}</label>)}
                                                    <div className="form-group multisteps-form__input">
                                                        <input id="confirmPassword" type="password" className="form-control2 form-control-default" name="" placeholder="eg. Steven" value={userEdit.confirmPassword} onChange={handleOnChange} />
                                                    </div>
                                                </div>

                                                <div className="MuiBox-root css-viexu0">
                                                    <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                                    <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" onClick={handleCreateUpdateUser}>
                                                        <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>&nbsp;add new user
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Footer */}
                    <footer className="footer pt-3 pb-4">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        Copyright © 2024,
                                        All rights reserved.
                                        <a href={undefined} className="font-weight-bold text-capitalize"> MultiSite</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
            {alert.show && (<div className={`loopple-alert loopple-alert-dismissible loopple-fade loopple-position-fixed loopple-z-index-9999 loopple-top-2 loopple-mx-auto loopple-text-center loopple-right-0 loopple-left-0 loopple-w-50 ${alert.status ? 'bg-gradient-success' : 'bg-gradient-warning'}`} role="alert">
                <strong>{alert.message ? alert.message : ''}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" onClick={handleClose}>✖</span></button>
            </div>)}
        </>
    )
}