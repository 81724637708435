
import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,//'http://127.0.0.1:40000/',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(function (config) {
    // const user = useSelector(state => state.user)
    // Do something before request is sent
    // console.info('user', user)
    let authKey = sessionStorage.getItem('token')
    if (authKey != null)
        config.headers["Authorization"] = "Bearer " + authKey;
    return config;
});

apiClient.apiPostNewsImage = async (file) => {
    try {
        
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('fileSize', file.file.size);
        formData.append('description', file.description)
        formData.append('alt', file.alt)

        const response = await apiClient.post("/images/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });



        if (response && response.data.status === 201) {
            return { filename: response.data.image_url, id: response.data.image_id, alt: response.data.alt }
        }

    } catch (error) {
        console.info(error)
        console.error("Error fetching data:", error.response.data.message);
    }
    // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
}

apiClient.bulkCreate = async (object, type) => {
    let path
    // let path = type === "Category" ? "/admin/categories/bulk-create" : type === "Tags" ? "/admin/hash-tags/bulk-create" : "/admin/sub-categories/bulk-create"
    // console.info('object', object)
    // console.info('type', type)
    switch (type) {
        case 'Category':
            path = "/admin/categories/bulk-create"
            break;
        case 'Tags':
            path = "/admin/hash-tags/bulk-create"
            break;
        case 'subCategories':
            path = "/admin/sub-categories/bulk-create"
            break;
        case 'banner':
            path = "/admin/banner/bulk-create"
            break;
        default:
            break;
    }

    try {
        const response = await apiClient.post(path, object);
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        // console.error("Error fetching data:", error);
        console.info('error', error)
    }
}

export default apiClient;