
import { React, useState, useEffect } from "react";
import SideMenu from "../../component/side-menu/side-menu";
import './all-post.css'
import apiClient from "../../instance/api-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faInfoCircle, faPencil, faSearch } from "@fortawesome/free-solid-svg-icons";
import Header from '../../component/header/header'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import DeletePrompt from "component/prompt/delete-prompt";

export default function AllPost() {
    const userRole = useSelector(state => state.user.role)
    const navigate = useNavigate();
    const [meta, setMeta] = useState([])
    const [posts, setPosts] = useState([])
    const [searchFilter, setSearchFilter] = useState({
        status: '',
        title: ''
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })

    const [postCount, setPostCount] = useState({
        all: {
            count: 0,
            name: 'All',
            value: ''
        },
        publish: {
            count: 0,
            name: 'Published',
            value: 'publish'

        },
        unpublish: {
            count: 0,
            name: 'Unpublished',
            value: 'unpublish'
        },
        schedule: {
            count: 0,
            name: 'Scheduled',
            value: 'schedule'
        }
    })
    // const [isLogin,] = useState(sessionStorage.getItem('token') ? true : false)
    const isLogin = useSelector(state => state.user.token) ? true : false
    const [searchTitle, setSearchTitle] = useState('')

    useEffect(() => {
        if (!isLogin) {
            navigate('/login')
        } else {
            loadPost();

        }
    }, []);

    const loadPost = async (searchFilter) => {
        getPostCount()
        try {
            const response = await apiClient.get('admin/contents', { params: searchFilter })
            if (response.status === 200) {
                setPosts(response.data.data)
                setMeta(response.data.meta)
            }
        } catch (error) {
            console.info(error)
        }
    }

    const getPostCount = async () => {
        try {
            const response = await apiClient.get('admin/contents/postCount')

            if (response.status === 200) {
                let tempPostCount = {
                    all: {
                        count: 0,
                        name: 'All',
                        value: ''
                    },
                    publish: {
                        count: 0,
                        name: 'Published',
                        value: 'publish'

                    },
                    unpublish: {
                        count: 0,
                        name: 'Unpublished',
                        value: 'unpublish'
                    },
                    schedule: {
                        count: 0,
                        name: 'Scheduled',
                        value: 'schedule'
                    }
                }
                response.data.data.flatMap((object) => {
                    tempPostCount[object.status].count = +object.count

                })

                tempPostCount.all.count = tempPostCount.publish.count + tempPostCount.unpublish.count + tempPostCount.schedule.count

                setPostCount(tempPostCount)
            }
        } catch (error) {
            console.info(error)
        }
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleFilter = async (event) => {
        const searchTempFilter = {
            status: event.target.id !== '' ? event.target.id : null
        }

        setSearchFilter((prev) => ({ ...prev, status: searchTempFilter.status }))

        loadPost(searchTempFilter)
    }

    const handleOnChangeTitle = async (event) => {
        setSearchTitle(event.target.value)
    }

    const handleSearchPosts = async () => {
        const searchTempFilter = {
            title: searchTitle
        }

        setSearchFilter((prev) => ({ ...prev, title: searchTempFilter.title }))

        loadPost(searchTempFilter)
    }

    const handleEditPost = (event) => {
        const postId = event.currentTarget.getAttribute('data-post-id');
        navigate('/EditPost/' + postId)
    }

    const handleDeletePost = (event) => {
        const postId = event.currentTarget.getAttribute('data-post-id');
        const postValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: postId,
            value: postValue,
        })
        // deletePost(postId)
    }

    // const deletePost = async (id) => {
    //     await apiClient.delete('admin/contents/' + id)
    //     loadPost()
    // }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/contents/' + id)

            if (response.status === 200) {
                loadPost()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const changeStatusColor = (status) => {
        if (status === 'publish') {
            return "badge badge-sm bg-gradient-success"
        } else if (status === 'schedule') {
            return "badge badge-sm bg-gradient-faded-info"
        }
        return "badge badge-sm bg-gradient-dark"
    }

    const nextPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasNextPage)
            searchTempFilter = { ...searchTempFilter, page: pages === -1 ? meta.pageCount : meta.page + 1 <= meta.pageCount ? meta.page + 1 : meta.pageCount }

        loadPost(searchTempFilter)
    }

    const prevPage = (pages) => {
        let searchTempFilter = { ...searchFilter }

        if (meta.hasPreviousPage)
            searchTempFilter = { ...searchTempFilter, page: pages === 1 ? 1 : meta.page - 1 > 0 ? meta.page - 1 : 1 }

        loadPost(searchTempFilter)
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Post"
                    message={`Post : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="main-content" id="panel">
                <nav aria-label="breadcrumb">
                    <h6 className="font-weight-med mb-0">Posts <a href={undefined}>/ All Posts</a></h6>
                </nav>
                <div className="container-fluid2 pt-3 row removable">
                    <div className="">
                        {/*All Posts:Start*/}
                        <div className="card mb-4">
                            <div className="subsubsub ms-md-auto2 pe-md-3 d-flex2 align-items-center">
                                <ul>
                                    <span>Filter : </span>
                                    {
                                        Object.entries(postCount).map(([key, countObject]) => (
                                            <li key={key}><a id={countObject.value} onClick={handleFilter} href={undefined}>{countObject.name} </a><span className="count">({countObject.count})</span> |</li>
                                        ))
                                    }
                                </ul>
                                <div className="input-group2 text-end">
                                    <span className="input-group-text2 text-body"><span className="input-group-text2 text-body"><FontAwesomeIcon icon={faSearch} /></span></span>
                                    <input type="text" className="form-control" value={searchTitle} onChange={handleOnChangeTitle} placeholder="Type here..." />
                                    <div className="col-5 text-end">
                                        <a onClick={handleSearchPosts} className="btn btn-sm bg-gradient-primary-blue mb-0" href={undefined}>Search Posts</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <div className="table-responsive p-0">
                                    <table className="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Author</th>
                                                <th className="text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Title</th>
                                                <th className="text-uppercase text-secondary text-xss font-weight-bolder opacity-7 ps-2">Categories</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Status</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Views</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Date</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Edit</th>
                                                <th className="text-center text-uppercase text-secondary text-xss font-weight-bolder opacity-7">Delete</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {posts && posts.length > 0 && posts.flatMap((post) => {
                                                return (
                                                    <tr key={`key-${post.id}`}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                {/* <div>
                                                                    <img src="assets/images/team-2.jpg" className="avatar avatar-sm me-3" />
                                                                </div> */}
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{post.user.name}</h6>
                                                                    {/* <p className="text-xs text-secondary mb-0">{post.user.email}</p> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-xl font-weight-bold title-wrap mb-0"><a href={undefined}>{post.title}</a></p>
                                                            <p className="text-xs text-secondary mb-0">---</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{post.categories && post.categories.length > 0 && post.categories.flatMap((categories, index) => {
                                                                return categories.name + (index < post.categories.length - 1 ? ',' : '')
                                                            })}</p>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span className={changeStatusColor(post.status)}>{post.status}</span>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <span className="text-secondary text-xs font-weight-bold">{post.views ? post.views : 0}</span>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <span className="text-secondary text-xs font-weight-bold">
                                                                <i className="fas fa-calendar text-dark me-2" aria-hidden="true" />
                                                                {formatDate(post.schedulePost)}</span>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span className="text-secondary text-xs font-weight-bold">
                                                                <a className="btn btn-link text-dark px-3 mb-0" href={undefined} onClick={handleEditPost} data-post-id={post.id}>
                                                                    <FontAwesomeIcon icon={faPencil} /> Edit
                                                                </a>
                                                            </span>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span className="text-secondary text-xs font-weight-bold">
                                                                <a className="btn btn-link text-danger px-3 mb-0" href={undefined} onClick={handleDeletePost} data-post-id={post.id} data-post-value={post.title}>
                                                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                                                </a>
                                                            </span>
                                                        </td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="tablenav-pages"><span className="displaying-num">{meta.itemCount} items</span>
                                        <span className="pagination-links"><span className="tablenav-pages-navspan button " aria-hidden="true" onClick={() => prevPage(1)}>«</span>
                                            <span className="tablenav-pages-navspan button " aria-hidden="true" onClick={prevPage}>‹</span>
                                            <span className="paging-input">
                                                <label htmlFor="current-page-selector" className="screen-reader-text">Current Page</label>
                                                <input className="current-page" id="current-page-selector" type="text" name="paged" defaultValue={1} size={1} value={meta.page} aria-describedby="table-paging" onChange={null} />
                                                <span className="tablenav-paging-text"> of  <span className="total-pages">{meta.pageCount}
                                                </span>
                                                </span>
                                            </span>
                                            <a className="next-page button" onClick={nextPage}>
                                                <span className="screen-reader-text">Next page</span>
                                                <span aria-hidden="true">›
                                                </span>
                                            </a>
                                            <a className="last-page button" onClick={() => nextPage(-1)}>
                                                <span className="screen-reader-text" >Last page</span>
                                                <span aria-hidden="true">»</span>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body align-items-center flex-column flex-md-row">
                            <form className="d-flex align-items-center w-100 justify-content-between" id="edit_user_2443442" acceptCharset="UTF-8" method="post" data-gtm-form-interact-id={0}><input type="hidden" name="authenticity_token" defaultValue="j8zXYDNtWFxqsNkL2J7LHC4Lfs6Q9KMui1wwKpY4lBrrZgemHUa3iTzBA7oS6LCs0J1UcR6WaqbU9bxmhq0org" autoComplete="off" />
                                <label className="form-check-label mb-0 d-flex align-items-center ms-0">
                                    <a href={undefined} data-tooltip data-tooltip-label="Note:" data-tooltip-message="by turning ON & click 'SAVE', all your scheduled posts will be published immediately.">
                                        {/* <i className="fas fa-info-circle me-2" /> */}
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </a>
                                    <span className="sr-only" /> Publish All Scheduled Posts
                                </label>
                                <div className="form-check form-switch ms-2 mb-0 me-auto">
                                    <input name="user[profile_published]" type="hidden" defaultValue={0} autoComplete="off" /><input className="form-check-input" type="checkbox" defaultValue={1} name="user[profile_published]" id="user_profile_published" data-gtm-form-interact-field-id={0} />
                                </div>
                                <input type="submit" name="commit" defaultValue="Save" className="btn bg-gradient-dark mb-0 me-2" data-disable-with="Save" />
                                <a href={undefined} className="btn bg-gradient-primary-blue mb-0"  >Preview</a>
                            </form>
                        </div>
                    </div>
                    {/* Footer */}
                    <footer className="footer pt-3 pb-4">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        Copyright © 2024,
                                        All rights reserved.
                                        <a href={undefined} className="font-weight-bold text-capitalize"> MultiSite</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}


AllPost.propTypes = {
    userRole: PropTypes.string,
};
