
import React, { useEffect, useState } from 'react'
import apiClient from '../../instance/api-client';
import PropTypes from 'prop-types';

UploadFilePreview.propTypes = {
    featuredImage: PropTypes.object,
    onChange: PropTypes.func.isRequired
}

export default function UploadFilePreview(prop) {
    const { featuredImage, onChange } = prop

    const [isOver, setIsOver] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [previewSrc, setPreviewSrc] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [altText, setAltText] = useState('');
    const [description, setDescription] = useState('');
    const [imageFile, setImageFile] = useState(null)

    useEffect(() => {
        if (featuredImage.filename) {
            setPreviewSrc(process.env.REACT_APP_API_URL + 'uploads/' + featuredImage.filename)
        }
    }, [featuredImage])

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsOver(true);
    };

    const handleDragLeave = () => {
        setIsOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsOver(false);
        const file = event.dataTransfer.files[0];
        setImageFile(file)
        // uploadFile(file);
        setShowDialog(true)
    };

    const handleOnClick = () => {
        document.querySelector('#fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(file){
            setImageFile(file)
            // uploadFile(file);
            setShowDialog(true)
        }
       
    };

    const handleImage = async (file, description, alt) => {

        const fileType = file.type;
        const fileSize = file.size;

        if (fileValidate(fileType, fileSize)) {
            const image = await apiClient.apiPostNewsImage({ file, description: description, alt: alt, fileSize: fileSize })
            onChange(image)
            setPreviewSrc(process.env.REACT_APP_API_URL + 'uploads/' + image.filename)
        }
    };

    const handleImageInsert = () => {
        handleImage(imageFile, description, altText)
        setShowDialog(false)
    }


    const fileValidate = (fileType, fileSize) => {
        const imagesTypes = ["jpeg", "png", "svg", "gif"];
        const isImage = imagesTypes.includes(fileType.split('/')[1]);

        if (!isImage) {
            alert("Please make sure to upload an Image File Type");
            return false;
        }

        if (fileSize > 2000000) {
            alert("Please note that your file should be less than 2 Megabytes");
            return false;
        }

        return true;
    };

    return (
        <>
            {showDialog && (
                <>
                    <div className="overlay short" id="overlay" style={{ marginTop: '-200px' }}>
                        <div className="overlay-background" id="overlay-background" ></div>
                        <div className="overlay-content" id="overlay-content">
                            <div className="fa fa-times fa-lg overlay-open" id="overlay-open" style={{position:'absolute',top:'10px',right:'10px'}}onClick={() => setShowDialog(false)}></div>
                            <h1 className="main-heading">Add alt text to your picture</h1>
                            <h3 className="blurb">Add your &quot;Alt Text&quot; and &quot;Description&quot; below —</h3><span className="blurb-tagline"></span>
                            <div className="signup-form" >
                                <label htmlFor="alt">Alt</label>
                                <input id="alt" type="text" name="alt" autoComplete="off" style={{color:'black',backgroundColor:'white'}} value={altText} onChange={(e) => setAltText(e.target.value)} />
                                <label htmlFor="description">Description</label>
                                <input id="description" type="text" name="description" autoComplete="off" style={{color:'black',backgroundColor:'white'}}  value={description} onChange={(e) => setDescription(e.target.value)} />
                                <button className="btn btn-outline" onClick={handleImageInsert} style={{backgroundColor:'white'}}><span>add</span></button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div id="uploadArea" className="upload-area">
                <div className="upload-area__header">
                    <h1 className="upload-area__title">Upload Your File</h1>
                    <p className="upload-area__paragraph">
                        File should be an image
                        <strong className="upload-area__tooltip">
                            Like
                            <span className="upload-area__tooltip-data">jpeg, .png, .svg, .gif</span>
                        </strong>
                    </p>
                    <p className="upload-area__paragraph2">Please note that your file should be less than <a>2 Megabytes</a></p>
                </div>

                <div
                    id="dropZoon"
                    className={`upload-area__drop-zoon drop-zoon ${isOver ? 'drop-zoon--over' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={handleOnClick}
                >
                    {previewSrc ? <img src={previewSrc} alt="Preview Image" id="previewImage" className="drop-zoon__preview-image" draggable="false" style={{ display: 'block' }} />
                        :
                        <> <span className="drop-zoon__icon">
                            <i className="bx bxs-file-image"></i>
                        </span>
                            <p className="drop-zoon__paragraph">Drop your file here or Click to browse</p>
                            {/* {loading && <span id="loadingText" className="drop-zoon__loading-text">Please Wait</span>} */}
                            {/* <img src={previewSrc} alt="Preview Image" id="previewImage" className="drop-zoon__preview-image" draggable="false" /> */}
                        </>
                    }
                    <input type="file" id="fileInput" className="drop-zoon__file-input" accept="image/*" onChange={handleFileChange} />
                </div>
            </div>
        </>
    )
}