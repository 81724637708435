import { UPDATED_CONTENT, CLEAR_UPDATED_CONTENT } from '../actionType/actionTypes';

const initialState = {
    content: {
        category: null,
    },
};


const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATED_CONTENT:
            return {
                ...state,
                category: { ...state.user, content: action.content }, // Update userRole inside user object
            };
        case CLEAR_UPDATED_CONTENT:
            return {
                ...state,
                user: { content: null }, // Update userRole inside user object
            };
        default:
            return state;
    }
};


export default contentReducer;