import React, { useState, useEffect } from 'react'
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import apiClient from "../../instance/api-client";
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import DeletePrompt from "../../component/prompt/delete-prompt";

export default function AllMainPages() {
    const [categories, setCategories] = useState([])
    const [pages, setPages] = useState([])
    const [pagesEdit, setPagesEdit] = useState({
        id: null,
        category: null,
        pageStatus: 'publish',
        priority: 0,
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })

    useEffect(() => {
        loadCategories()
        loadPages()
    }, [])

    const loadPages = async () => {
        try {
            const response = await apiClient.get('admin/pages')

            if (response.status === 200) {
                setPages(response.data)
            }
        } catch (error) {
            console.info("Error fetching data:", error);
        }
    }

    const loadCategories = async () => {
        try {
            const response = await apiClient.get('admin/categories',{params:{take:99999}})

            if (response.status === 200) {
                setCategories(response.data.data.flatMap((object) => { return { ...object, label: object.name, value: object.id } }))
            }
        } catch (error) {
            console.info("Error fetching data:", error);
        }
    }

    const handlePagesOnChange = (Category) => {
        setPagesEdit((prev) => ({ ...prev, category: Category }))
    }

    const handleCancelEditPages = () => {
        setPagesEdit({ id: null, category: null, pageStatus: 'publish', metaTitle: '', metaKeyword: '', metaDescription: '', })
    }

    const handlePageStatusOnChange = (event) => {
        const status = event.target.value
        setPagesEdit((prev) => ({
            ...prev,
            pageStatus: status
        }))
    }

    const handleAddNewPage = () => {
        handleCreateNewPage()
    }

    const handleCreateNewPage = async () => {
        let category = { ...pagesEdit.category }
        if (category.__isNew__) {
            const result = await apiClient.bulkCreate([{ ...category, name: category.label }], "Category")

            if (result)
                category = { categoryId: result.data[0].id }
        }

        let response

        try {
            const params = {
                ...pagesEdit,
                categoryId: category.categoryId ?? category.id,
                status: pagesEdit.pageStatus,
                priority: +pagesEdit.priority,
            }

            if (pagesEdit.id) {
                response = await apiClient.patch('admin/pages/' + pagesEdit.id, params)

            } else {
                response = await apiClient.post('admin/pages', params)
            }

            if (response.data.statusCode === 200) {
                handleCancelEditPages()
                loadCategories()
                loadPages()
            }
        } catch (error) {
            console.info("Error creating pages data:", error);
        }
    }

    const handleEditPage = (event) => {
        const pageId = event.currentTarget.getAttribute('data-post-id');
        const editPage = pages.find((page) => page.id == pageId);

        if (editPage) {
            setPagesEdit({
                id: pageId,
                category: {
                    categoryId: editPage.categories.id,
                    name: editPage.categories.name,
                    value: editPage.categories.id,
                    label: editPage.categories.name
                },
                pageStatus: editPage.status,
                priority: editPage.priority,
                metaTitle: editPage.metaTitle ? editPage.metaTitle : '',
                metaKeyword: editPage.metaKeyword ? editPage.metaKeyword : '',
                metaDescription: editPage.metaDescription ? editPage.metaDescription : ''
            });
        }
    }

    const handleDeletePage = (event) => {
        const pageId = event.currentTarget.getAttribute('data-post-id');
        const pageValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: pageId,
            value: pageValue
        })
    }

    // const deletePage = async (id) => {
    //     await apiClient.delete('admin/pages/' + id)
    //     loadPages()
    // }

    const changeStatusColor = (status) => {
        if (status === "publish") {
            return "badge badge-sm bg-gradient-success"
        }
        return "badge badge-sm bg-gradient-secondary"
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return date.toLocaleDateString(undefined, options);
    }

    const checkValueText = (value) => {
        return value ?? '';
    }

    const handlePriorityOnChagne = (event) => {
        const value = event.target.value
        setPagesEdit((prev) => ({
            ...prev,
            priority: value
        }))
    }

    const handleInputOnChange = (event) => {
        const id = event.target.id;
        setPagesEdit((prev) => ({
            ...prev,
            [id]: event.target.value
        }));
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const onActionDialogComplete = async (id) => {
        try {
            const response = await apiClient.delete('admin/pages/' + id)


            if (response.status === 200) {
                loadPages()
            }

        } catch (error) {
            console.error('Error:', error.response.data.message);
            alert(error.response.data.message)
        }

        onActionDialogClose()
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Page"
                    message={`Page : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="g-sidenav-show">
                <div className="main-content" id="panel">
                    <div className="container-fluid2 pt-3 row removable">
                        <div className="card mb-4">
                            <div className="card-header pb-0">
                                <h6>All Pages</h6>
                            </div>
                            <div className="card-body px-0 pt-0 pb-2">
                                <div className="table-responsive p-0">
                                    <table className="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Author</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Priority</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created Date</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Edit</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pages && pages.length > 0 && pages.flatMap((page, index) => {
                                                return (
                                                    <tr key={'page' + index}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{checkValueText(page.categories.name)}</h6>
                                                                    <p className="text-xs text-secondary mb-0">blackcat@multisite-dashboard.com</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{checkValueText(page.user.name)}</p>
                                                            <p className="text-xs text-admin text-secondary mb-0">{checkValueText(page.user.role)}</p>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span className={changeStatusColor(page.status)}>{checkValueText(page.status)}</span>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <span>{checkValueText(page.priority)}</span>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                                                            <span className="text-secondary text-xs font-weight-bold">{formatDate(page.createdAt)}</span>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <a className="btn btn-link text-dark px-3 mb-0" href={undefined} data-post-id={page.id} onClick={handleEditPage}>
                                                                <FontAwesomeIcon icon={faPencil} />Edit
                                                            </a>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <a className="btn btn-link text-danger px-3 mb-0" href={undefined} data-toggle="tooltip" data-original-title="Preview" data-post-id={page.id} data-post-value={page.categories.name} onClick={handleDeletePage}>
                                                                <FontAwesomeIcon icon={faTrash} /> Delete
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-8">
                                        <h6 className="mb-0">Add New Pages</h6>
                                        <p className="text-xs text-instructions form-text text-muted3 ms-0">Type in your page title and &apos;CLICK&apos; + Add New Page</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form-margin-top">
                                    <div id="basic-info">
                                        <div className="card-body d-sm-flex pt-0">
                                            <div className="row">
                                                <div className="col-sm-5 col-7 d-flex align-items-center mb-sm-0 mb-4">
                                                    Status
                                                    <select className="form-control3 form-control-sm" onChange={handlePageStatusOnChange} value={pagesEdit.pageStatus}>
                                                        <option value="publish">Publish</option>
                                                        <option value="unpublish">Unpublished</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-1 col-7 d-flex align-items-center mb-sm-0 mb-4" style={{ paddingLeft: '50px' }}>
                                                    Priority
                                                    <input className="form-control3 form-control-sm" type='number' min="0" max="9" value={pagesEdit.priority} onChange={handlePriorityOnChagne} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <div className="form-group">
                                                <label className="form-control-label" htmlFor="input-address">Page Title</label>
                                                <CreatableSelect options={categories} value={pagesEdit.category} onChange={handlePagesOnChange} />
                                            </div>
                                            <span style={{ marginLeft: '40px' }}>Meta Use</span>
                                            <div className="col-lg-12">
                                                <div className="form-group2">
                                                    <label className="form-control-label" htmlFor="input-city">Meta Title</label>
                                                    <input id="metaTitle" className="form-control2" placeholder="Add Title" value={pagesEdit.metaTitle} type="text" onChange={handleInputOnChange} />
                                                </div>
                                                <div className="form-group2">
                                                    <label className="form-control-label" htmlFor="input-city">Meta Keyword</label>
                                                    <input id="metaKeyword" className="form-control2" placeholder="Add Keyword" value={pagesEdit.metaKeyword} type="text" onChange={handleInputOnChange} />
                                                    <span style={{ fontSize: '11px', backgroundColor: 'rgba(255,255,0,0.5)' }}>*sample : 满分足球,足球新闻,足球动态,英超积分榜,球队排名,中超积分榜,积分榜 each words end with comma</span>
                                                </div>
                                                <div className="form-group2">
                                                    <label className="form-control-label" htmlFor="input-city">Meta Description</label>
                                                    <input id="metaDescription" className="form-control2" placeholder="Add Description" value={pagesEdit.metaDescription} type="text" onChange={handleInputOnChange} />
                                                </div>
                                            </div> */}
                                            <div className="MuiBox-root css-viexu0">
                                                <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                                <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" onClick={handleAddNewPage}>
                                                    <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span> {pagesEdit.id ? 'update pages' : 'add new page'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <!-- Footer --> */}

                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                    <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}