import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../../component/side-menu/side-menu"
import Header from "../../component/header/header"
import apiClient from "../../instance/api-client";
import DeletePrompt from "../../component/prompt/delete-prompt";

export default function SubCategories() {

    const [subCategories, setSubCategories] = useState([])
    const [subCategoryEdit, setSubCategoryEdit] = useState({
        id: null,
        name: null,
        slug: null,
        description: null,
        urlName: null
    })
    const [deleteHook, setDeleteHook] = useState({
        show: false,
        id: null,
        value: null,
    })

    useEffect(() => {
        loadSubCategories()
    }, [])

    const loadSubCategories = async () => {
        try {
            const response = await apiClient.get('admin/sub-categories')

            if (response.status === 200) {
                setSubCategories(response.data)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleEdit = (event) => {
        const categoryId = event.currentTarget.getAttribute('data-post-id');
        const edit = subCategories.filter((category) => category.id == categoryId)
        if (edit.length > 0) {
            setSubCategoryEdit({ id: edit[0].id, slug: edit[0].slug, description: edit[0].description, name: edit[0].name })
        }

    }

    const handleDelete = (event) => {
        const tagId = event.currentTarget.getAttribute('data-post-id');
        const tagValue = event.currentTarget.getAttribute('data-post-value');
        setDeleteHook({
            show: true,
            id: tagId,
            value: tagValue
        })
    }

    const handleCreateUpdateCategory = async () => {
        let response

        try {
            if (subCategoryEdit.name) {
                if (subCategoryEdit.id)
                    response = await apiClient.patch('admin/sub-categories/' + subCategoryEdit.id, subCategoryEdit)
                else
                    response = await apiClient.post('admin/sub-categories', subCategoryEdit)

                if (response.data.statusCode === 200) {
                    handleCancelEditSubCategory()
                    loadSubCategories()
                }
            }
        } catch (error) {
            alert(error.response.data.message)
        }

    }

    const handleCancelEditSubCategory = () => {
        setSubCategoryEdit({ id: null, slug: '', description: '', name: '' })
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleInputChange = (event) => {
        const id = event.target.id;
        setSubCategoryEdit((prev) => ({
            ...prev,
            [id]: event.target.value
        }));
    }

    const onActionDialogClose = () => {
        setDeleteHook((prev) => ({ ...prev, show: false }))
    }

    const onActionDialogComplete = async (id) => {

        try {
            const response = await apiClient.delete('admin/sub-categories/' + id)

            if (response.status === 200) {
                loadSubCategories()
            }

        } catch (error) {
            alert(error.response.data.message)
        }
        onActionDialogClose()
    }

    return (
        <>
            <SideMenu />
            <Header />
            {deleteHook.show && (
                <DeletePrompt
                    title="Sub Categories"
                    message={`Sub Categories : ${deleteHook.value}`}
                    id={deleteHook.id}
                    handleDeletePrompt={onActionDialogComplete}
                    handleClosePrompt={onActionDialogClose}
                />
            )}
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable">
                    <div className="card mb-4">
                        <div className="card-header pb-0 subsubsub2">
                            <h6>All Sub Categories</h6>
                            <span className="count">(6)</span>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Url Name</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Slug</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Descriptions</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Created</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Edit</th>
                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {subCategories && subCategories.length > 0 && subCategories.flatMap((subCategory, index) => {
                                            return (
                                                <tr key={'subCategory' + index}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{subCategory.name}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{subCategory.urlName}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs text-center">{subCategory.slug}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs text-center">{subCategory.description}</p>
                                                    </td>

                                                    <td className="align-middle text-center">
                                                        <i className="fas fa-calendar text-dark me-2" aria-hidden="true"></i>
                                                        <span className="text-secondary text-xs font-weight-bold">{formatDate(subCategory.createdAt)}</span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <a className="btn btn-link text-dark px-3 mb-0" href={null} data-post-id={subCategory.id} onClick={handleEdit}>
                                                            <FontAwesomeIcon icon={faPencil} /> Edit</a>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <a className="btn btn-link text-danger px-3 mb-0" href={null} data-post-id={subCategory.id} data-post-value={subCategory.name} onClick={handleDelete}>
                                                            <FontAwesomeIcon icon={faTrash} /> Delete</a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h6 className="mb-0">Add New Category</h6>
                                    <p className="text-xs form-text text-muted3 ms-0">Type in the name, slug and descriptions and &apos;CLICK&apos; + Add New Sub Category</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form className="form-margin-top">
                                <div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label">Name</label>
                                            <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="name" onChange={handleInputChange} value={subCategoryEdit.name} />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">URL Name</label>
                                            <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: 世界杯, 切尔西, AC米兰, C罗" id="urlName" onChange={handleInputChange} value={subCategoryEdit.urlName} />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Slug</label>
                                            <input type="text" data-input="true" className="form-control2 datetimepicker flatpickr-input" placeholder="i.e: worldcup, chelsea, ac-milan, christiano-ronaldo" id="slug" onChange={handleInputChange} value={subCategoryEdit.slug} />
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Descriptions</label>
                                            <input type="text" data-input="true" className="form-control2" placeholder="i.e: *English Premier Leagues fixture, livescore, standings and player transfer related topic. (*Optional-You may leave it blank as well.)" id="description" onChange={handleInputChange} value={subCategoryEdit.description} />
                                        </div>
                                    </div>

                                    <div className="MuiBox-root css-viexu0">
                                        <h6 className="MuiTypography-root MuiTypography-h6 css-noxxp0"></h6>
                                        <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary bg-gradient-info MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: subCategoryEdit.id ? '#82d616' : 'rgb(68 197 255)' }} onClick={handleCreateUpdateCategory}>
                                            {subCategoryEdit.id ?
                                                (<>
                                                    <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true"></span>Update Sub Category
                                                </>) :
                                                (<>
                                                    <span className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit css-kyh2wa" aria-hidden="true">add</span>Add New Sub Category
                                                </>)
                                            }
                                        </button>
                                        <p></p>
                                        {subCategoryEdit.id && (
                                            <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-exssr5" tabIndex="0" type="button" style={{ backgroundColor: 'gray' }} onClick={handleCancelEditSubCategory}>
                                                Cancel
                                            </button>
                                        )}

                                    </div>
                                </div>

                                {/* <!-- Footer --> */}

                                <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/popper.min.js"></script>
                                <script src="https://demos.creative-tim.com/soft-ui-dashboard/assets/js/core/bootstrap.min.js"></script>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}