import React, { useState } from 'react'
import SideMenu from '../../component/side-menu/side-menu'
import Header from '../../component/header/header'
import './add-media.css'
import apiClient from '../../instance/api-client';

export default function AddMedia() {
    const [files, setFiles] = useState({});
    const [counter, setCounter] = useState(0);


    const addFile = (file) => {
        const isImage = file.type.match("image.*");
        const objectURL = URL.createObjectURL(file);

        if (isImage) {
            setFiles(prevFiles => ({
                ...prevFiles,
                [objectURL]: file
            }));
        }
    };

    const handleFileChange = (e) => {
        for (const file of e.target.files) {
            addFile(file);
        }
    };

    const hasFiles = (e) => {
        const types = Array.from(e.dataTransfer.types);
        return types.includes("Files");
    };

    const dragEnterHandler = (e) => {
        e.preventDefault();
        if (!hasFiles(e)) {
            return;
        }
        setCounter(prevCounter => prevCounter + 1);
    };

    const dragLeaveHandler = () => {

        setCounter(prevCounter => prevCounter - 1);
    };

    const dragOverHandler = (e) => {
        if (hasFiles(e)) {
            e.preventDefault();
        }
    };

    const dropHandler = (e) => {
        e.preventDefault();
        for (const file of e.dataTransfer.files) {
            addFile(file);
        }
        setCounter(0);
    };

    const handleSubmit = async () => {

        // for (const objectURL in files) {
        //     await uploadFile(files[objectURL])
        // }

        // alert(`success upload file:\n${JSON.stringify(files)}`);
        const fileKeys = Object.keys(files);

        // Upload all files concurrently
        await Promise.all(fileKeys.map(async (objectURL) => {
            await uploadFile(files[objectURL]);
        }));

        alert(`Success upload files:\n${JSON.stringify(files)}`);
    };

    const uploadFile = async (file) => {
        const fileType = file.type;
        const fileSize = file.size;

        if (fileValidate(fileType, fileSize)) {

            const temp = { file, description: "", alt: "", fileSize: fileSize }
            await apiClient.apiPostNewsImage(temp)
        }
    };

    const fileValidate = (fileType, fileSize) => {
        const imagesTypes = ["jpeg", "png", "svg", "gif"];
        const isImage = imagesTypes.includes(fileType.split('/')[1]);

        if (!isImage) {
            alert("Please make sure to upload an Image File Type");
            return false;
        }

        if (fileSize > 5000000) {
            alert("Please note that your file should be less than 5 Megabytes");
            return false;
        }

        return true;
    };

    const handleCancel = () => {
        setFiles({});
    };

    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.2.0/tailwind.min.css" />
            <SideMenu />
            <Header />
            <div className="main-content" id="panel">
                <div className="container-fluid2 pt-3 row removable" style={{ textAlign: 'center', overflow: 'visible' }}>
                    <div className="mt-4 row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <div
                                        className={`w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md ${counter > 0 ? 'draggedover' : ''}`}

                                    >
                                    </div>

                                    <section className="h-full overflow-auto p-3 w-full h-full flex flex-col section" >
                                        <header className="border-dashed border-2 border-light-blue-400 py-3 flex flex-col justify-center items-center" onDrop={dropHandler}
                                            onDragOver={dragOverHandler}
                                            onDragLeave={dragLeaveHandler}
                                            onDragEnter={dragEnterHandler}>
                                            <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">Drag and drop your files anywhere or</p>
                                            <input id="hidden-input" type="file" multiple className="hidden" onChange={handleFileChange} />
                                            <button id="button" className="mb-0 btn btn-outline-dark btn-sm" style={{ width: '200px' }} onClick={() => document.getElementById("hidden-input").click()}>
                                                Upload a file
                                            </button>
                                            <p className="drop-zoon__paragraph">Drop your files here or Click &apos;Upload a files&apos;</p>
                                        </header>

                                        <ul id="gallery" className="flex flex-1 flex-wrap m-1" >
                                            {Object.entries(files).map(([objectURL, file]) => (

                                                <>

                                                    <article className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer  shadow-sm image-card" style={{ width: '144px', height: '80px', marginLeft: '10px' }}>

                                                        {/* <section className="flex flex-col rounded-md text-xs break-words z-20 absolute top-0 py-2 px-3" style={{ width: '144px', height: '80px', marginLeft: '20px', backgroundColor: 'blue' }}> */}
                                                        {file.type.match("image.*") && (
                                                            <img alt="upload preview" className="img-preview object-cover rounded-md" src={objectURL} style={{ width: '144px', height: '80px' }} />
                                                        )}
                                                        <h1 className="flex-1 group-hover:text-blue-800 p-1 size text-xs filename">{file.name}</h1>
                                                        <h1 className="p-1 size text-xs text-gray-700 filesize" style={{ marginRight: '20px' }}>{file.size > 1024 ? (file.size > 1048576 ? Math.round(file.size / 1048576) + "mb" : Math.round(file.size / 1024) + "kb") : file.size + "b"}</h1>
                                                        <button style={{ width: '50px', fontSize: '12px', marginLeft: '20px', marginTop: '-24px' }} onClick={() => setFiles(prevFiles => {
                                                            const { ...rest } = prevFiles;
                                                            return rest;
                                                        })}>
                                                            Delete
                                                        </button>
                                                        {/* </section> */}
                                                    </article>
                                                </>
                                            ))}
                                        </ul>
                                    </section>

                                    <footer className="flex justify-end px-8 pb-8 pt-4">
                                        <button id="submit" className="btn mb-0 bg-gradient-info btn-md null null mb-0" style={{ width: '200px' }} onClick={handleSubmit}>
                                            Upload now
                                        </button>
                                        <button id="cancel" className="btn mb-0 bg-gradient-secondary btn-md null null ms-auto mb-0" style={{ width: '200px' }} onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}