import { SET_USER, LOGOUT } from '../actionType/actionTypes';

const initialState = {
    role: null, // or some initial value
    token: null,
    // other user properties
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                role: action.role, token: action.token, // Update userRole inside user object
            };
        case LOGOUT:
            return {
                role: null, token: null, // Update userRole inside user object
            };
        default:
            return state;
    }
};

export default userReducer;