import React from 'react';
import './side-menu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faImage, faReceipt, faUserTie, faPen, faWrench, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export default function SideMenu() {
    const userRole = useSelector(state => state.user.role)
    const redirect = useNavigate();

    const isActive = (path) => (existPath(path) ? "nav-link active" : "nav-link");
    const isShow = (path) => (existPath(path) ? "collapse show" : "collapse");

    const existPath = (path) => {
        let exist = window.location.href.includes(path);

        if (!exist) {
            switch (path) {
                case 'Post':
                    exist = window.location.href.includes('Categories') || window.location.href.includes('Tags');
                    break;
                case 'Media':
                    exist = window.location.href.includes('Library') || window.location.href.includes('AddNewFile');
                    break;
                case 'Settings':
                    exist = window.location.href.includes('Domain') || window.location.href.includes('WhiteList');
                    break;
                case 'Affiliate':
                    exist = window.location.href.includes('Banner');
                    break;
                default:
                    break;
            }
        }

        return exist;
    }

    return (
        <div>
            <nav className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start position-absolute ms-3 bg-white loopple-fixed-start" id="sidenav-main" data-sidebar="true" data-sidebar-value="52">
                <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href={undefined}>
                                <div className="border-radius-md text-center me-2 d-flex align-items-center logo-kl">
                                    <img src={"/images/logo-multisite.png"} />
                                </div>
                            </a>
                        </li>

                        <hr className="horizontal dark mt-0" />

                        <li className="nav-item">
                            <a className={isActive("Dashboard")} href={undefined} onClick={() => redirect('/Dashboard')}>
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <FontAwesomeIcon icon={faHome} />
                                </div>
                                <span className="nav-link-text ms-1">Dashboard</span>
                            </a>
                        </li>

                        {/* <!--Menu-Posts Category--> */}
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#postsExamples" className={isActive("Post") + " collapsed"} aria-controls="postsExamples" role="button" aria-expanded="false">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                    <FontAwesomeIcon icon={faPen} />
                                </div>
                                <span className="nav-link-text ms-1">Posts</span>
                            </a>
                            <div className={isShow('Post')} id="postsExamples">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <a className={isActive("/AllPost")} onClick={() => redirect('/AllPost')} >
                                            <span className="sidenav-normal"> All Posts </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={isActive("/NewPost")} href={null} onClick={() => redirect('/NewPost')}>
                                            <span className="sidenav-normal"> New Posts </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={isActive("/Categories")} href={null} onClick={() => redirect('/Categories')}>
                                            <span className="sidenav-normal"> Categories </span>
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className={isActive("/SubCategories")} href={null} onClick={() => redirect('/SubCategories')}>
                                            <span className="sidenav-normal"> Sub Categories </span>
                                        </a>
                                    </li> 
                                    <li className="nav-item">
                                        <a className={isActive("/Tags")} href={null} onClick={() => redirect('/Tags')}>
                                            <span className="sidenav-normal"> Tags </span>
                                        </a>
                                    </li>*/}
                                </ul>
                            </div>
                        </li>

                        {/* <!--Menu-Pages Category--> */}
                        {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#pagesExamples" className={isActive("Page") + " collapsed"} aria-controls="pagesExamples" role="button" aria-expanded="false">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                        <FontAwesomeIcon icon={faReceipt} />
                                    </div>
                                    <span className="nav-link-text ms-1">Pages</span>
                                </a>
                                <div className={isShow('Page')} id="pagesExamples">
                                    <ul className="nav ms-4 ps-3">
                                        <li className="nav-item">
                                            <a className={isActive("/MainPage")} href={null} onClick={() => redirect('/MainPage')}>
                                                <span className="sidenav-normal"> Main Pages </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={isActive("/SubPage")} href={null} onClick={() => redirect('/SubPage')}>
                                                <span className="sidenav-normal"> Sub Pages </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )}

                        {/* <!--Menu-Media Category--> */}
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#mediaExamples" className={isActive("Media") + " collapsed"} aria-controls="mediaExamples" role="button" aria-expanded="false">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                    <FontAwesomeIcon icon={faImage} />
                                </div>
                                <span className="nav-link-text ms-1">Media</span>
                            </a>
                            <div className={isShow('Media')} id="mediaExamples">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <a className={isActive("/Library")} href={null} onClick={() => redirect('/Library')}>
                                            <span className="sidenav-normal"> Library </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={isActive("/AddNewFile")} href={null} onClick={() => redirect('/AddNewFile')}>
                                            <span className="sidenav-normal"> Add New File </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#bannerExamples" className={isActive("Banner") + " collapsed"} aria-controls="bannerExamples" role="button" aria-expanded="false">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                        <FontAwesomeIcon icon={faMoneyBill} />
                                    </div>
                                    <span className="nav-link-text ms-1">Affiliate</span>
                                </a>
                                <div className={isShow('Banner')} id="bannerExamples">
                                    <ul className="nav ms-4 ps-3">
                                        <li className="nav-item">
                                            <a className={isActive("/Banner")} href={null} onClick={() => redirect('/Banner')}>
                                                <span className="sidenav-normal"> Banner </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        )}

                        {/* <!--setting--> */}

                        {(userRole === "SUPER ADMIN" || userRole === "ADMIN") && (
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#settingExamples" className={isActive("Settings") + " collapsed"} aria-controls="settingExamples" role="button" aria-expanded="false">
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                        <FontAwesomeIcon icon={faWrench} />
                                    </div>
                                    <span className="nav-link-text ms-1">Settings</span>
                                </a>
                                <div className={isShow('Settings')} id="settingExamples">
                                    <ul className="nav ms-4 ps-3">
                                        <li className="nav-item">
                                            <a className={isActive("/Domain")} href={null} onClick={() => redirect('/Domain')}>
                                                <span className="sidenav-normal"> Domains </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={isActive("/WhiteList")} href={null} onClick={() => redirect('/WhiteList')}>
                                                <span className="sidenav-normal"> WhiteList </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>)}






                        {/* <!--Menu-User Category--> */}
                        {/* <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#userExamples" className={isActive("User") + " collapsed"} aria-controls="userExamples" role="button" aria-expanded="false">
                                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2">
                                    <FontAwesomeIcon icon={faUserTie} />
                                </div>
                                <span className="nav-link-text ms-1">User</span>
                            </a>
                            <div className={isShow('User')} id="userExamples">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <a className={isActive("/AddNewUser")} href={null} onClick={() => redirect('/AddNewUser')}>
                                            <span className="sidenav-normal"> Add New User </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={isActive("/AllUser")} href={null} onClick={() => redirect('/AllUser')}>
                                            <span className="sidenav-normal"> All User </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="sidenav-footer mx-3 mt-3 pt-3">
                                <div className="card card-background shadow-none card-background-mask-secondary" id="sidenavCard">
                                    <div className="full-background" style={{ backgroundImage: 'url("/images/white-curved.jpg")' }}></div>
                                    <div className="card-body text-start p-3 w-100">
                                        <div className="icon icon-shape icon-sm bg-white shadow text-center mb-3 d-flex align-items-center justify-content-center border-radius-md">
                                            <FontAwesomeIcon icon={faScrewdriverWrench} />
                                        </div>
                                        <div className="docs-info">
                                            <h6 className="text-white up mb-0">Need Help?</h6>
                                            <p className="text-xs font-weight-bold">CALL ITDV Team, Today!</p>
                                            <a href="#" target="_blank" className="btn btn-white btn-sm2 w-100 mb-0"><i className="fa fa-phone me-1" aria-hidden="true"></i> 1800 888 999</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <script src="assets/js/core/popper.min.js"></script>
                            <script src="assets/js/core/bootstrap.min.js"></script>
                        </li> */}
                        {(userRole === "ADMIN" || userRole === "SUPER ADMIN") && (<>
                            <hr className="horizontal dark mt-3x" />
                            <li className="nav-item mt-3">
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6"><a className="btn-outline-primary mb-0 me-3">Account pages</a></h6>
                            </li>
                            <li className="nav-item">
                                <a className={isActive("User")} href={undefined} onClick={() => redirect('/User')}>
                                    <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                                        <FontAwesomeIcon icon={faUserTie} />
                                    </div>
                                    <span className="nav-link-text ms-1">User</span>
                                </a>
                            </li>
                        </>)}

                    </ul>
                </div>
            </nav>
        </div>
    )
}
